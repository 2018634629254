import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { allUser, setSearchValue, setUserAccessData } from '../../features/user/userSlice'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

const Search = () => {
    const { page_number, searchValue } = useSelector(state => state.user)
    const dispatch = useDispatch()
    const dataSearchFunction = async (event) => {
        event.preventDefault()
        dispatch(setUserAccessData([]))
        dispatch(allUser({ data: searchValue, page_number: 1 }))
    }

    useEffect(() => {
        let fetching = false
        dispatch(allUser({
            data: {
                type: searchValue.type,
                search_value: '',
                confirm: searchValue.confirm,
                value: searchValue.value,
                fetchType: searchValue.fetchType
            }, page_number: 1
        }))

        dispatch(setUserAccessData([]))
        return () => {
            fetching = true
        }
    }, [searchValue.value])

    const inputSearchValue = (event) => {
        dispatch(setSearchValue({
            type: searchValue.type,
            search_value: event.target.value,
            confirm: searchValue.confirm,
            value: searchValue.value,
            fetchType: searchValue.fetchType
        }))
        if (event.target.value === '') {
            dispatch(allUser({
                data: {
                    type: searchValue.type,
                    search_value: event.target.value,
                    confirm: searchValue.confirm,
                    value: searchValue.value,
                    fetchType: searchValue.fetchType
                }, page_number: 1
            }))
        }
    }

    return (
        <div className='search_container'>
            <form onSubmit={dataSearchFunction} className='form_container'>
                <input className='form_input' placeholder='Search user' type="text" value={searchValue.search_value} onChange={inputSearchValue} />
                <input className='form_input' type="submit" value='Search' />
            </form>
            <FormControl sx={{ width: '20%' }}>
                <InputLabel id="demo-simple-select-label">Filter By</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={searchValue.value}
                    label="Search By"
                    size='small'
                    onChange={(event) => {
                        dispatch(setSearchValue({ type: searchValue.type, search_value: '', value: parseInt(event.target.value), confirm: parseInt(event.target.value) < 3 ? parseInt(event.target.value) : (4 - parseInt(event.target.value)), fetchType: parseInt(event.target.value) === 0 ? 0 : parseInt(event.target.value) > 2 ? 2 : 1 }))
                    }}
                >
                    <MenuItem value={0}>All</MenuItem>
                    <MenuItem value={3}>Active</MenuItem>
                    <MenuItem value={4}>In-Active</MenuItem>
                    <MenuItem value={1}>Confirm</MenuItem>
                    <MenuItem value={2}>Reject</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}

export default Search