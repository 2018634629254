import { Alert, Box, LinearProgress, Paper, Stack, Switch, Typography } from '@mui/material';
import React, { memo, useCallback, useMemo } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux'
import { allUser, allocateOfficeToUser } from '../../features/user/userSlice';
import { setFormOpenOrClose } from '../../features/common/commonSlice';
import { allOffice, setData as setOfficeData } from '../../features/office/officeSlice';
import './accessTable.scss'




const createData = (name, action, access) => {
    return { name, action, access };
}

const arrayOfFinance = (financeId) => {
    return financeId.split(',').filter(Boolean)
}

const columns = [
    { id: 'name', label: 'Head Office Name', minWidth: 200, align: 'start' },
    { id: 'action', label: 'Action', minWidth: 100, align: 'center' },
];



const AccessTable = () => {
    const { totalOffice, message, totalSearchData, searchValue, page_number } = useSelector(state => state.office)
    const { userAccessData, updateMessage } = useSelector(state => state.user)
    const dispatch = useDispatch()


    // const officeDetails = useCallback((value) => {
    //     const values = totalOffice.filter(user => user.id === value)
    //     dispatch(setOfficeData(values))
    //     dispatch(setFormOpenOrClose({ type: 'office', open: true }))
    //     return
    // })

    const rows = useMemo(() => {
        return totalOffice.map((office) => {
            const access = !arrayOfFinance(userAccessData[0].finance_id).map(value => parseInt(value)).includes(office.id)
            return createData(office.name, office.id, access)
        });
    }, [totalOffice, userAccessData])


    return (
        <>
            {
                updateMessage.message.length > 0 && <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert sx={{ display: 'flex', justifyContent: 'center' }} severity={updateMessage.loading === 'pending' ? 'info' : 'error'}>{updateMessage.message}</Alert>
                </Stack>
            }
            {
                message.length !== 0 ? <Typography sx={{ mt: 1, p: 2, textAlign: 'center' }} component={Paper}>
                    {message}
                </Typography> : <>
                    <Typography color='white' sx={{ mt: 0, p: 1, textAlign: 'center', bgcolor: 'orange' }} component={Paper}>
                        {userAccessData[0].name}
                    </Typography>
                    <InfiniteScroll
                        dataLength={totalOffice.length}
                        className='infinite_scroll_access'
                        next={() => {
                            dispatch(allOffice({ searchValue: searchValue, page_number: page_number }))
                        }}
                        height='calc(100vh - 160px)'
                        hasMore={totalOffice.length < totalSearchData}
                        loader={
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                        }
                    >
                        <table className='table_container'>
                            <thead sx={{ height: '10px' }} className='table_head_wrapper'>
                                <tr className='table_content_wrapper'>
                                    {columns.map((column) => (
                                        <th
                                            sx={{ border: '.2px solid lightGray', minWidth: column.minWidth }}
                                            className='table_row'
                                            size='small'
                                            key={column.id}
                                            style={{ textAlign: column.align }}
                                        >
                                            {column.label}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className='table_body_wrapper'>
                                {rows
                                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <tr className='table_body_content_wrapper' key={row.id} style={{ zIndex: '999' }}>
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    if (column.id === 'action') {
                                                        return (
                                                            <td key={column.id} className='table_body_content' style={{ textAlign: column.align }}>
                                                                <div>
                                                                    <span>No</span>
                                                                    <span>  <Switch size='small' checked={row.access} onClick={(event) => {
                                                                        event.stopPropagation()
                                                                        dispatch(allocateOfficeToUser({ id: userAccessData[0].id, office_id: row.action }))
                                                                    }} /></span>
                                                                    <span>Yes</span>
                                                                </div>
                                                            </td>
                                                        );
                                                    }
                                                    return (
                                                        <td key={column.id} className='table_body_content' style={{ textAlign: column.align }}>
                                                            {column.format && typeof value === 'number'
                                                                ? column.format(value)
                                                                : value}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table >
                    </InfiniteScroll >
                </>
            }
        </>
    )
}

export default memo(AccessTable)