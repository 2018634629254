import React from 'react'
import VehicleTable from '../../components/vehicle/VehicleTable'
import './vehicle.scss'
import Search from '../../components/vehicle/Search'
import VehicleDetailsTable from '../../components/vehicle/VehicleDetailsTable'
import { useSelector } from 'react-redux'

const Vehicle = () => {
    const { vehicleDetails } = useSelector(state => state.vehicle)
    return (
        <div className="vehicle_container">
            <div className="vehicle_container_left">
                <Search />
                <VehicleTable />
            </div>
            <div className="vehicle_container_right">
                {Object.keys(vehicleDetails.details).length > 0 && <VehicleDetailsTable />}
            </div>
        </div>
    )
}

export default Vehicle