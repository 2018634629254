import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API_URL } from '../../global/production_URL'
import axios from 'axios'
import { serverDateString } from '../common/commonSlice'
import dayjs from 'dayjs'



// ######################----GETTING ALL FINANCE FUNCTION FROM SERVER----#############----START----###########################
export const allDetail = createAsyncThunk(
    'detail/all',
    async ({ page_number, searchValue }) => {
        const option = {
            url: `${API_URL}details/all?page=20&page_number=${page_number}`,
            method: 'POST',
            data: searchValue,
            headers: {
                token: localStorage.getItem('token')
            }
        }
        try {
            const response = await axios(option)
            return response
        } catch (error) {
            return error.message
        }
    }
)
// ######################----GETTING ALL FINANCE FUNCTION FROM SERVER----#############----END----#############################


const initialState = {
    totalDetails: [],
    message: '',
    page_number: 1,
    totalSearchData: 0,
    searchValue: {
        date: serverDateString(dayjs(Date.now())),
        search_value: ''
    }
}

export const detailsSlice = createSlice({
    name: 'details',
    initialState,
    reducers: {
        setPageNumber: (state, action) => {
            state.page_number = action.payload
        },
        setSearchValue: (state, action) => {
            state.searchValue = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(allDetail.pending, (state, action) => {
            if (action.meta.arg.page_number < 2)
                state.message = 'Please wait... We are fetching data.'
        }).addCase(allDetail.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.message = ''
                state.totalSearchData = action.payload.data.total
                if (action.meta.arg.page_number > 1) {
                    state.totalDetails = state.totalDetails.concat(action.payload.data.response)
                    state.page_number += 1
                } else {
                    state.page_number = 2
                    state.totalDetails = action.payload.data.response
                }
            } else {
                state.message = 'Details Not Found'
            }
        }).addCase(allDetail.rejected, (state, action) => {
            state.message = `Sorry We can't fetch data. Please try again`
        })
    }
})

// Action creators are generated for each case reducer function
export const { setPageNumber, setData, setSearchValue, setUpdateMessage, setAlertOpenOrClose } = detailsSlice.actions

export default detailsSlice.reducer