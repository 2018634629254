import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { allFinance, setFinanceId, setSearchValue } from '../../features/finance/financeSlice'

const Search = ({ finance_id }) => {
    const { page_number, searchValue, financeId } = useSelector(state => state.finance)
    const { headOffice } = useSelector(state => state.office)
    const dispatch = useDispatch()
    
    const dataSearchFunction = async (event) => {
        event.preventDefault()
        dispatch(allFinance({ searchValue, page_number: 1, finance_id: headOffice.id }))
        return
    }

    useEffect(() => {
        let fetching = false
        dispatch(setSearchValue(''))
        dispatch(allFinance({ searchValue: '', page_number: 1, finance_id: finance_id }))
        return () => {
            fetching = true
        }
    }, [finance_id])

    const inputSearchValue = (event) => {
        dispatch(setSearchValue(event.target.value))
        if (event.target.value === '') {
            dispatch(allFinance({ searchValue: '', page_number: 1, finance_id: headOffice.id }))
        }
    }

    return (
        <form onSubmit={dataSearchFunction} className='form_container'>
            <input className='form_input' placeholder='Search Finance' type="text" value={searchValue} onChange={inputSearchValue} />
            <input className='form_input' type="submit" value='Search' />
        </form>
    )
}

export default Search