import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API_URL } from '../../global/production_URL'
import axios from 'axios'


export const allUser = createAsyncThunk(
    'user/all',
    async ({ data, page_number }) => {
        const options = {
            url: `${API_URL}user/search?page=25&page_number=${page_number}`,
            method: 'POST',
            data: data,
            headers: {
                token: localStorage.getItem('token')
            }
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error.message
        }
    }
)




export const allocateOfficeToUser = createAsyncThunk(
    'user/office/allocate',
    async ({ id, office_id }) => {
        const options = {
            url: `${API_URL}user/access/${id}`,
            method: 'PUT',
            data: { office_id },
            headers: {
                token: localStorage.getItem('token')
            }
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error.message
        }
    }
)



export const userPlanUpdate = createAsyncThunk(
    'user/plan/update',
    async ({ id, data }) => {
        const options = {
            url: `${API_URL}user/plan/${id}`,
            method: 'PUT',
            data: data,
            headers: {
                token: localStorage.getItem('token')
            }
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error.message
        }
    }
)




export const updateUserDetails = createAsyncThunk(
    'user/update/details',
    async ({ id, data }) => {
        const options = {
            url: `${API_URL}user/details/${id}`,
            method: 'PUT',
            data: data,
            headers: {
                token: localStorage.getItem('token')
            }
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error.message
        }
    }
)






export const actionUserRequest = createAsyncThunk(
    'user/action/request',
    async ({ id, confirm }) => {
        const options = {
            url: `${API_URL}user/accept/user/${id}`,
            method: 'PUT',
            data: { confirm },
            headers: {
                token: localStorage.getItem('token')
            }
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error.message
        }
    }
)





const initialState = {
    totalConfirmUser: [],
    message: '',
    page_number: 1,
    totalSearchData: 0,
    searchValue: {
        type: 0,
        search_value: '',
        confirm: 0,
        fetchType: 0,
        value: 0
    },
    userAccessData: [],
    updateMessage: {
        loading: '',
        message: ''
    },
    updatePlanMessage: {
        loading: '',
        message: ''
    },
    updateUserMessage: {
        loading: '',
        message: ''
    },
    actionMessage: {
        loading: '',
        message: ''
    }
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserData: (state, action) => {

        },
        setPageNumber: (state, action) => {
            state.page_number = action.payload
        },
        setSearchValue: (state, action) => {
            state.searchValue = action.payload
        },
        setUserAccessData: (state, action) => {
            state.userAccessData = state.totalConfirmUser.filter(user => user.id == action.payload)
        }
    },
    extraReducers: builder => {
        builder.addCase(allUser.pending, (state, action) => {
            if (action.meta.arg.page_number < 2)
                state.message = 'Please wait... We are fetching data.'
        }).addCase(allUser.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.message = ''
                if (action.meta.arg.page_number > 1) {
                    state.totalConfirmUser = state.totalConfirmUser.concat(action.payload.data.response)
                    state.totalSearchData = action.payload.data.total
                    state.page_number += 1
                } else {
                    state.page_number = 2
                    state.totalSearchData = action.payload.data.total
                    state.totalConfirmUser = action.payload.data.response
                }
            } else {
                state.message = 'User Not Found'
            }
        }).addCase(allUser.rejected, (state, action) => {
            state.message = `Sorry We can't fetch data. Please try again`
        })




        builder.addCase(allocateOfficeToUser.pending, (state, action) => {
            state.updateMessage = {
                loading: 'pending',
                message: 'Please wait... We are allocate Head Office to user.'
            }
        }).addCase(allocateOfficeToUser.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.updateMessage = {
                    loading: '',
                    message: ''
                }
                state.totalConfirmUser = state.totalConfirmUser.map((user) => {
                    if (user.id === action.meta.arg.id) {
                        return action.payload.data[0]
                    } else {
                        return user
                    }
                })
                state.userAccessData = action.payload.data
            } else {
                state.updateMessage = {
                    loading: 'fulfilled',
                    message: `Allocate Office to user failed`
                }
            }
        }).addCase(allocateOfficeToUser.rejected, (state, action) => {
            state.updateMessage = {
                loading: 'reject',
                message: `Sorry We can't allocate Office to user. Please try again.`
            }
        })



        builder.addCase(userPlanUpdate.pending, (state, action) => {
            state.updatePlanMessage = {
                loading: 'pending',
                message: 'Please wait... We are update user plan.'
            }
        }).addCase(userPlanUpdate.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.updatePlanMessage = {
                    loading: '',
                    message: ''
                }
                state.totalConfirmUser = state.totalConfirmUser.map((user) => {
                    if (user.id === action.meta.arg.id) {
                        return { ...user, ...action.meta.arg.data }
                    } else {
                        return user
                    }
                })
            } else {
                state.updatePlanMessage = {
                    loading: 'fulfilled',
                    message: `User plan update failed`
                }
            }
        }).addCase(userPlanUpdate.rejected, (state, action) => {
            state.updatePlanMessage = {
                loading: 'reject',
                message: `Sorry We can't update user plan. Please try again.`
            }
        })



        builder.addCase(updateUserDetails.pending, (state, action) => {
            state.updateUserMessage = {
                loading: 'pending',
                message: 'Please wait... We are update user details.'
            }
        }).addCase(updateUserDetails.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.updateUserMessage = {
                    loading: '',
                    message: ''
                }
                if (action.meta.arg.data.type > 0) {
                    state.totalConfirmUser = state.totalConfirmUser.filter((user) => user.id !== action.meta.arg.id)
                } else {
                    state.totalConfirmUser = state.totalConfirmUser.map((user) => {
                        if (user.id === action.meta.arg.id) {
                            return { ...user, ...action.meta.arg.data }
                        } else {
                            return user
                        }
                    })
                }
            } else {
                state.updateUserMessage = {
                    loading: 'fulfilled',
                    message: `User details update failed`
                }
            }
        }).addCase(updateUserDetails.rejected, (state, action) => {
            state.updateUserMessage = {
                loading: 'reject',
                message: `Sorry We can't update user details. Please try again.`
            }
        })



        builder.addCase(actionUserRequest.pending, (state, action) => {
            state.actionMessage = {
                loading: 'pending',
                message: `Please wait... We are ${action.meta.arg.confirm === 1 ? 'accepting' : 'rejecting'} user request.`
            }
        }).addCase(actionUserRequest.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.actionMessage = {
                    loading: '',
                    message: ''
                }
                state.totalConfirmUser = state.totalConfirmUser.filter((user) => user.id !== action.meta.arg.id)
                state.totalSearchData -= 1
            } else {
                state.actionMessage = {
                    loading: 'fulfilled',
                    message: `User request ${action.meta.arg.confirm === 1 ? 'accepting' : 'rejecting'} failed`
                }
            }
        }).addCase(actionUserRequest.rejected, (state, action) => {
            state.actionMessage = {
                loading: 'reject',
                message: `Sorry We can't ${action.meta.arg.confirm === 1 ? 'accepting' : 'rejecting'} user. Please try again.`
            }
        })
    }
})

// Action creators are generated for each case reducer function
export const { setPageNumber, setUserData, setSearchValue, setUserAccessData } = userSlice.actions

export default userSlice.reducer