import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { setSearchValue, setVehicleDetails, vehicleConfirmRequest } from '../../features/dashboard/dashboardSlice'

const VehicleRequestSearch = () => {
    const { page_number, searchValue } = useSelector(state => state.dashboard)
    const dispatch = useDispatch()
    const dataSearchFunction = async (event) => {
        event.preventDefault()
        dispatch(setVehicleDetails({
            id: '',
            details: {}
        }))
        dispatch(vehicleConfirmRequest({ searchValue, page_number: 1 }))
    }


    useEffect(() => {
        let fetching = false
        dispatch(setSearchValue({
            search_value: ''
        }))
        dispatch(setVehicleDetails({
            id: '',
            details: {}
        }))
        dispatch(vehicleConfirmRequest({ searchValue, page_number: 1 }))
        return () => {
            fetching = true
        }
    }, [])

    const inputSearchValue = (event) => {
        dispatch(setSearchValue({
            search_value: event.target.value
        }))
        if (event.target.value === '') {
            dispatch(vehicleConfirmRequest({
                searchValue: {
                    search_value: ''
                }, page_number: 1
            }))
        }
    }


    return (
        <div className='search_container'>
            <form onSubmit={dataSearchFunction} className='form_container'>
                <input className='form_input' placeholder='Search vehicle confirm request' type="text" value={searchValue.search_value} onChange={inputSearchValue} />
                <input className='form_input' type="submit" value='Search' />
            </form>
        </div>
    )
}

export default VehicleRequestSearch