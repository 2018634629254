import { AccountCircleRounded, AdminPanelSettingsRounded, ApartmentRounded, CloudUploadRounded, DashboardCustomizeRounded, DriveEtaRounded, GroupRounded, Home, LockRounded, Menu, ViewCompactRounded } from '@mui/icons-material'
import React, { useState } from 'react'
import './sidebar.scss'
import { Link } from 'react-router-dom'
import { Button, FormControlLabel, FormGroup } from '@mui/material'
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux'
import { setLoggedIn } from '../../features/auth/authSlice'



const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 55,
    height: 28,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 25,
        height: 25,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff',
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));


const Sidebar = () => {
    const [show, setShow] = useState(true)
    const [activeIndex, setActiveIndex] = useState(0)
    const { isAdmin } = useSelector(state => state.auth)
    const dispatch = useDispatch()

    const menuItems = [
        {
            id: 1,
            name: 'Dashboard',
            icon: <DashboardCustomizeRounded />,
            subMenu: [],
            link: '/'
        },
        {
            id: 2,
            name: 'Vehicle',
            icon: <DriveEtaRounded />,
            subMenu: [],
            link: '/vehicle'
        },
        {
            id: 3,
            name: 'Upload',
            icon: <CloudUploadRounded />,
            subMenu: [],
            link: '/file-upload'
        },
        {
            id: 4,
            name: 'Head Office',
            icon: <ApartmentRounded />,
            subMenu: [],
            link: '/head-office'
        },
        {
            id: 5,
            name: 'Users',
            icon: <GroupRounded />,
            link: '/user',
            subMenu: [
                // {
                //     id: 19,
                //     name: 'All',
                //     icon: '',
                //     link: '/all',
                // },
                // {
                //     id: 15,
                //     name: 'Active',
                //     icon: '',
                //     link: '/active',
                // },
                // {
                //     id: 16,
                //     name: 'In-Active',
                //     icon: '',
                //     link: '/in-active',
                // },
                // {
                //     id: 17,
                //     name: 'Rejected',
                //     icon: '',
                //     link: '/rejected',
                // },
                // {
                //     id: 18,
                //     name: 'Confirm',
                //     icon: '',
                //     link: '/confirm',
                // },
            ]
        },
        {
            id: 11,
            name: 'Administrator',
            icon: <AdminPanelSettingsRounded />,
            link: '/administrator',
            subMenu: [
                // {
                //     id: 12,
                //     name: 'Admin',
                //     icon: '',
                //     link: 'admin'
                // },
                // {
                //     id: 13,
                //     name: 'Sub-Admin',
                //     icon: '',
                //     link: '/sub-admin',
                // },
            ]
        },
        {
            id: 8,
            name: 'Details',
            icon: <ViewCompactRounded />,
            subMenu: [],
            link: '/details',
        },
        // {
        //     id: 9,
        //     name: 'Profile',
        //     icon: <AccountCircleRounded />,
        //     subMenu: [],
        //     link: '/profile',
        // },
        {
            id: 11,
            name: 'Register',
            icon: <AccountCircleRounded />,
            subMenu: [],
            link: '/administrator/auth/register'
        },
        {
            id: 10,
            name: 'Sign Out',
            icon: <LockRounded />,
            subMenu: [],
            link: '/administrator/auth'
        },
    ]

    return (
        <div className="sidebar_container" style={{ left: show ? '0px' : '0px', width: show ? '0px' : '240px' }}>
            <div className="sidebar_menu_btn_wrapper">
                <span className="menu_show_icon" onClick={() => setShow(!show)}>
                    <Menu className='icon' />
                </span>
            </div>
            <div className="sidebar_menu_item_wrapper">
                <ul className="menu_item_container">

                    {
                        menuItems.map((item, index) => {
                            return (
                                <Link key={item.id} to={item.subMenu.length === 0 && item.link}>
                                    <li className={`menu_item ${activeIndex === index && 'active'} ${(((item.id === 3) || (item.id === 11)) && !isAdmin) && 'hide'}`} onClick={() => {
                                        if (item.id === 10) {
                                            localStorage.clear()
                                            dispatch(setLoggedIn(false))
                                        } else {
                                            item.subMenu.length === 0 && setActiveIndex(index)
                                        }
                                        // item.subMenu.length === 0 && setShow(!show)
                                    }}>
                                        <span style={{ display: 'flex' }}> <span className="menu_icon">{item.icon}</span>
                                            <span className="menu_content">{item.name}</span></span>
                                        {
                                            item.subMenu.length > 0 && <ul className='sub_menu' >
                                                {
                                                    item.subMenu.map((subItem) => {
                                                        return (
                                                            <Link key={subItem.name} to={item.link + subItem.link}>
                                                                <li className={`sub_menu_item`} onClick={() => {
                                                                    setActiveIndex(index)
                                                                }}>
                                                                    <span className="sub_menu_content">{subItem.name}</span>
                                                                </li>
                                                            </Link>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        }
                                    </li>
                                </Link>
                            )
                        })
                    }
                </ul>
                {/* <FormGroup>
                    <FormControlLabel
                        label="Dark Mode"
                        control={<MaterialUISwitch size='small' sx={{ m: 1 }} defaultChecked />}
                    />
                </FormGroup> */}
            </div>
        </div>
    )
}

export default Sidebar