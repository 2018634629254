import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API_URL } from '../../global/production_URL'
import axios from 'axios'


export const login = createAsyncThunk(
    'login',
    async ({ data }) => {
        const options = {
            url: `${API_URL}auth/login`,
            method: 'POST',
            data: data
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error.message
        }
    }
)

export const register = createAsyncThunk(
    'register',
    async ({ data }) => {
        const options = {
            url: `${API_URL}auth/register`,
            method: 'POST',
            headers: {
                token: localStorage.getItem('token')
            },
            data: data
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error.message
        }
    }
)


export const RefreshLogin = createAsyncThunk(
    'login/refresh',
    async () => {
        const options = {
            url: `${API_URL}auth/refresh`,
            method: 'POST',
            headers: {
                token: localStorage.getItem('token')
            }
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error.message
        }
    }
)




const initialState = {
    userLoginData: {},
    loggedIn: false,
    message: {
        loading: '',
        message: ''
    },
    refreshMessage: {
        loading: '',
        message: ''
    },
    registerMessage: {
        loading: '',
        message: ''
    },
    isAdmin: false
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoggedIn: (state, action) => {
            state.loggedIn = action.payload
        },
        setRegisterMessage: (state, action) => {
            state.registerMessage = {
                loading: '',
                message: ''
            }
        }
    },
    extraReducers: builder => {
        builder.addCase(login.pending, (state, action) => {
            state.message = {
                loading: 'pending',
                message: 'Please wait...'
            }
        }).addCase(login.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.message = {
                    loading: '',
                    message: ''
                }
                state.loggedIn = true
                localStorage.setItem('token', action.payload.data.token)
                if (action.payload.data.response.type === 1) {
                    state.isAdmin = true
                } else {
                    state.isAdmin = false
                }
                state.userLoginData = action.payload.data.response
            } else {
                state.loggedIn = false
                localStorage.clear()
                state.message = {
                    loading: 'fulfilled',
                    message: 'Invalid Credentials'
                }
            }
        }).addCase(login.rejected, (state, action) => {
            state.loggedIn = false
            localStorage.clear()
            state.message = {
                message: `Sorry you can't login this time. Please try again`,
                loading: 'reject'
            }
        })




        builder.addCase(RefreshLogin.pending, (state, action) => {
            state.refreshMessage = {
                loading: 'pending',
                message: 'Please wait...'
            }
        }).addCase(RefreshLogin.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.refreshMessage = {
                    loading: '',
                    message: ''
                }
                state.loggedIn = true
                if (action.payload.data[0].type === 1) {
                    state.isAdmin = true
                } else {
                    state.isAdmin = false
                }
                state.userLoginData = action.payload.data[0]
            } else {
                state.loggedIn = false
                localStorage.clear()
                state.refreshMessage = {
                    loading: 'fulfilled',
                    message: 'Invalid Credentials'
                }
            }
        }).addCase(RefreshLogin.rejected, (state, action) => {
            state.loggedIn = false
            localStorage.clear()
            state.refreshMessage = {
                message: `Sorry you can't login this time. Please try again`,
                loading: 'reject'
            }
        })



        builder.addCase(register.pending, (state, action) => {
            state.registerMessage = {
                loading: 'pending',
                message: 'Please wait... We are creating new user.'
            }
        }).addCase(register.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.registerMessage = {
                    loading: 'success',
                    message: 'User successfully created'
                }
            } else {
                state.registerMessage = {
                    loading: 'fulfilled',
                    message: `Sorry, user can't created`
                }
            }
        }).addCase(register.rejected, (state, action) => {
            state.registerMessage = {
                message: `Sorry user can't created this time. Please try again`,
                loading: 'reject'
            }
        })
    }
})

// Action creators are generated for each case reducer function
export const { setLoggedIn, setRegisterMessage } = authSlice.actions

export default authSlice.reducer