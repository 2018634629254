import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { API_URL } from '../../global/production_URL'

export const allVehicle = createAsyncThunk(
    'record/vehicle',
    async () => {
        const options = {
            url: `${API_URL}records/vehicle`,
            method: 'POST',
            headers: {
                token: localStorage.getItem('token')
            }
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error
        }
    }
)


export const allConfirmVehicle = createAsyncThunk(
    'record/vehicle/confirm',
    async ({ confirm }) => {
        const options = {
            url: `${API_URL}records/confirm`,
            method: 'POST',
            data: { confirm },
            headers: {
                token: localStorage.getItem('token')
            }
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error
        }
    }
)



export const allUser = createAsyncThunk(
    'record/user',
    async ({ confirm }) => {
        const options = {
            url: `${API_URL}records/user`,
            method: 'POST',
            data: { confirm },
            headers: {
                token: localStorage.getItem('token')
            }
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error
        }
    }
)

export const allOffice = createAsyncThunk(
    'record/office',
    async () => {
        const options = {
            url: `${API_URL}records/office`,
            method: 'POST',
            headers: {
                token: localStorage.getItem('token')
            }
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error
        }
    }
)

export const allFinance = createAsyncThunk(
    'record/finance',
    async () => {
        const options = {
            url: `${API_URL}records/finance`,
            method: 'POST',
            headers: {
                token: localStorage.getItem('token')
            }
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error
        }
    }
)


const initialState = {
    totalVehicle: 0,
    totalVehicleConfirm: 0,
    totalVehicleConfirmPending: 0,
    totalUser: 0,
    totalActiveUser: 0,
    totalInActiveUser: 0,
    totalRejectUser: 0,
    totalConfirmUser: 0,
    totalRequestUser: 0,
    totalOffice: 0,
    totalFinance: 0
}

export const recordSlice = createSlice({
    name: 'record',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {

        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(allVehicle.pending, (state, action) => {
        })
        builder.addCase(allVehicle.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.totalVehicle = action.payload.data.total
            }

        })
        builder.addCase(allVehicle.rejected, (state, action) => {
        })



        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(allConfirmVehicle.pending, (state, action) => {
        })
        builder.addCase(allConfirmVehicle.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                if (action.meta.arg.confirm === 1) {
                    state.totalVehicleConfirm = action.payload.data.total
                } else {
                    state.totalVehicleConfirmPending = action.payload.data.total
                }
            }

        })
        builder.addCase(allConfirmVehicle.rejected, (state, action) => {
        })


        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(allUser.pending, (state, action) => {
        })
        builder.addCase(allUser.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                if (action.meta.arg.confirm === 0) {
                    state.totalRequestUser = action.payload.data.total
                } if (action.meta.arg.confirm === 1) {
                    state.totalConfirmUser = action.payload.data.total
                } else {
                    state.totalRejectUser = action.payload.data.total
                }
            }

        })

        builder.addCase(allUser.rejected, (state, action) => {
        })

        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(allFinance.pending, (state, action) => {
        })
        builder.addCase(allFinance.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.totalFinance = action.payload.data.total
            }

        })
        builder.addCase(allFinance.rejected, (state, action) => {
        })

        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(allOffice.pending, (state, action) => {
        })
        builder.addCase(allOffice.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.totalOffice = action.payload.data.total
            }

        })
        builder.addCase(allOffice.rejected, (state, action) => {
        })
    },
})

// Action creators are generated for each case reducer function
export const { } = recordSlice.actions

export default recordSlice.reducer