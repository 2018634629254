import React from 'react'
import Sidebar from '../../components/sidebar/Sidebar'
import { Outlet } from 'react-router-dom'
import './mainLayout.scss'

const MainLayout = () => {
    return (
        <div className="main_layout_container">
            <div className="main_layout_wrapper">
                <div className="main_layout_left">
                    <Sidebar />
                </div>
                <div className="main_layout_right">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default MainLayout