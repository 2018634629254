import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API_URL } from '../../global/production_URL'
import axios from 'axios'


export const createFinance = createAsyncThunk(
    'finance/create',
    async ({ data }) => {
        console.log(data);
        const options = {
            url: `${API_URL}finance/create/new`,
            method: 'POST',
            data: data,
            headers: {
                token: localStorage.getItem('token')
            }
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error.message
        }
    }
)


export const allFinance = createAsyncThunk(
    'finance/all',
    async ({ searchValue, page_number, finance_id }) => {
        const options = {
            url: `${API_URL}finance/all?page=25&page_number=${page_number}`,
            method: 'POST',
            data: {
                search_value: searchValue,
                finance_id: finance_id
            },
            headers: {
                token: localStorage.getItem('token')
            }
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error.message
        }
    }
)


export const updateFinance = createAsyncThunk(
    'finance/update',
    async ({ finance_id, data }) => {
        const options = {
            url: `${API_URL}finance/update/${finance_id}`,
            method: 'PUT',
            data: data,
            headers: {
                token: localStorage.getItem('token')
            }
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error.message
        }
    }
)



export const deleteFinance = createAsyncThunk(
    'finance/delete',
    async ({ finance_id }) => {
        const options = {
            url: `${API_URL}finance/delete/${finance_id}`,
            method: 'DELETE',
            headers: {
                token: localStorage.getItem('token')
            }
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error.message
        }
    }
)




export const deleteFinanceRecords = createAsyncThunk(
    'finance/delete/records',
    async ({ finance_id }) => {
        const options = {
            url: `${API_URL}finance/delete/finance-record/${finance_id}`,
            method: 'DELETE',
            headers: {
                token: localStorage.getItem('token')
            }
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error.message
        }
    }
)



const initialState = {
    totalFinance: [],
    message: '',
    page_number: 1,
    totalSearchData: 0,
    searchValue: '',
    financeId: '',
    data: [],
    updateMessage: {
        loading: '',
        message: ''
    },
    alertOpenOrClose: false,
}

export const financeSlice = createSlice({
    name: 'finance',
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload
        },
        setPageNumber: (state, action) => {
            state.page_number = action.payload
        },
        setSearchValue: (state, action) => {
            state.searchValue = action.payload
        },
        setUpdateMessage: (state, action) => {
            state.updateMessage = action.payload
        },
        setAlertOpenOrClose: (state, action) => {
            state.alertOpenOrClose = action.payload
        },
        setFinanceId: (state, action) => {
            state.financeId = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(allFinance.pending, (state, action) => {
            if (action.meta.arg.page_number < 2)
                state.message = 'Please wait... We are fetching data.'
        }).addCase(allFinance.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.message = ''
                if (action.meta.arg.page_number > 1) {
                    state.totalFinance = state.totalFinance.concat(action.payload.data.response)
                    state.page_number += 1
                } else {
                    state.page_number = 2
                    state.totalSearchData = action.payload.data.total
                    state.totalFinance = action.payload.data.response
                }
            } else {
                state.message = 'Finance Not Found'
            }
        }).addCase(allFinance.rejected, (state, action) => {
            state.message = `Sorry We can't fetch data. Please try again`
        })




        builder.addCase(updateFinance.pending, (state, action) => {
            state.updateMessage = {
                loading: 'pending',
                message: 'Please wait... We are updating data.'
            }
        }).addCase(updateFinance.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.updateMessage = {
                    loading: '',
                    message: ''
                }
                state.totalFinance = state.totalFinance.map((finance) => {
                    if (finance.id === action.meta.arg.finance_id) {
                        return { ...finance, ...action.meta.arg.data }
                    } else {
                        return finance
                    }
                })
            } else {
                state.updateMessage = {
                    loading: 'fulfilled',
                    message: 'Finance did not update'
                }
            }
        }).addCase(updateFinance.rejected, (state, action) => {
            state.updateMessage = {
                loading: 'rejected',
                message: `Sorry We can't update data. Please try again`
            }
        })




        builder.addCase(deleteFinance.pending, (state, action) => {
            state.updateMessage = {
                loading: 'pending',
                message: 'Please wait... We are deleting data.'
            }
        }).addCase(deleteFinance.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.updateMessage = {
                    loading: '',
                    message: ''
                }
                state.totalFinance = state.totalFinance.filter(finance => finance.id !== action.meta.arg.finance_id)
                state.totalSearchData -= 1
            } else {
                state.updateMessage = {
                    loading: 'fulfilled',
                    message: 'Finance did not deleted'
                }
            }
        }).addCase(deleteFinance.rejected, (state, action) => {
            state.updateMessage = {
                loading: 'rejected',
                message: `Sorry We can't delete data. Please try again`
            }
        })





        builder.addCase(createFinance.pending, (state, action) => {
            state.updateMessage = {
                loading: 'pending',
                message: 'Please wait... We are creating new Finance.'
            }
        }).addCase(createFinance.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.updateMessage = {
                    loading: '',
                    message: ''
                }
                if (state.totalFinance.length > 0) {
                    state.totalFinance = state.totalFinance.concat(action.payload.data)
                    state.totalSearchData += 1
                }
            } else {
                state.updateMessage = {
                    loading: 'fulfilled',
                    message: 'Finance did not create'
                }
            }
        }).addCase(createFinance.rejected, (state, action) => {
            state.updateMessage = {
                loading: 'rejected',
                message: `Sorry We can't create finance. Please try again`
            }
        })





        builder.addCase(deleteFinanceRecords.pending, (state, action) => {
            // state.updateMessage = {
            //     loading: 'pending',
            //     message: 'Please wait... We are creating new Finance.'
            // }
        }).addCase(deleteFinanceRecords.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                // state.updateMessage = {
                //     loading: '',
                //     message: ''
                // }
                if (state.totalFinance.length > 0) {
                    state.totalFinance = state.totalFinance.map(finance => {
                        if (finance.id === action.meta.arg.finance_id) {
                            return { ...finance, total_record: 0 }
                        } else {
                            return finance
                        }
                    })
                }
            } else {
                // state.updateMessage = {
                //     loading: 'fulfilled',
                //     message: 'Finance did not create'
                // }
            }
        }).addCase(deleteFinanceRecords.rejected, (state, action) => {
            // state.updateMessage = {
            //     loading: 'rejected',
            //     message: `Sorry We can't create finance. Please try again`
            // }
        })
    }
})

// Action creators are generated for each case reducer function
export const { setPageNumber, setData, setSearchValue, setUpdateMessage, setAlertOpenOrClose, setFinanceId } = financeSlice.actions

export default financeSlice.reducer