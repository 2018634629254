import { Box, LinearProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React, { memo, useCallback, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux'
import { allUser, setUserAccessData } from '../../features/user/userSlice';
import FormDialog from './UserForm';
import { setFormOpenOrClose } from '../../features/common/commonSlice';
import './user.scss'
import KYCImage from './KYCImage';




const createData = (name, mobile, address, action, kyc, image, id) => {
    return { name, mobile, address, action, kyc, image, id };
}



const columns = [
    { id: 'name', label: 'Name', minWidth: 200, align: 'start' },
    { id: 'mobile', label: 'Mobile', minWidth: 30, align: 'center', },
    { id: 'address', label: 'Address', minWidth: 170, align: 'start', },
    { id: 'action', label: 'Action', minWidth: 100, align: 'center' },
];



const UserTable = () => {
    const { totalConfirmUser, message, totalSearchData, searchValue, page_number } = useSelector(state => state.user)
    const { isAdmin } = useSelector(state => state.auth)

    const [data, setData] = useState([])
    const dispatch = useDispatch()


    const userDetails = useCallback(({ id, type }) => {
        const values = totalConfirmUser.filter(user => user.id === id)
        setData(values)
        if (type === 'user') {
            dispatch(setFormOpenOrClose({ type: 'user', open: true }))
        } else {
            dispatch(setFormOpenOrClose({ type: 'kycImage', open: true }))
        }
        return
    })


    const rows = useMemo(() => {
        return totalConfirmUser.map((user) => {
            return createData(user.name, user.mobile, user.address, user.id, user.kyc_image, user.image, user.id)
        });
    }, [totalConfirmUser])


    return (
        message.length !== 0 ? <Typography sx={{ mt: 1, p: 2, textAlign: 'center' }} component={Paper}>
            {message}
        </Typography> : <>
            <InfiniteScroll
                dataLength={totalConfirmUser.length}
                className='infinite_scroll_user'
                next={() => {
                    dispatch(allUser({ data: searchValue, page_number: page_number }))
                }}
                height='calc(100vh - 120px)'
                hasMore={totalConfirmUser.length < totalSearchData}
                loader={
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                }
            >
                <table className='table_container'>
                    <thead sx={{ height: '10px' }} className='table_head_wrapper'>
                        <tr className='table_content_wrapper'>
                            {columns.map((column) => (
                                <th
                                    sx={{ border: '.2px solid lightGray', minWidth: column.minWidth }}
                                    className='table_row'
                                    size='small'
                                    key={column.id}
                                    style={{ textAlign: column.align }}
                                >
                                    {column.label}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className='table_body_wrapper'>
                        {rows
                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <tr className='table_body_content_wrapper' key={row.id} onClick={(event) => {
                                        event.stopPropagation()
                                        if (isAdmin)
                                            userDetails({ id: row.id, type: 'user' })
                                    }} >
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            if (column.id === 'action') {
                                                return (
                                                    <td key={column.id} style={{ textAlign: column.align }} className='table_body_content'>
                                                        {isAdmin && <button className='user_btn' size='small' sx={{ marginLeft: '5px' }} onClick={(event) => {
                                                            event.stopPropagation()
                                                            userDetails({ id: row.id, type: 'image' })
                                                        }}>Kyc</button>}
                                                        <button className='user_btn' color='success' size='small' sx={{ marginLeft: '5px' }} onClick={(event) => {
                                                            event.stopPropagation()
                                                            dispatch(setUserAccessData(row.action))
                                                        }}>Access</button>
                                                    </td>
                                                );
                                            }
                                            return (
                                                <td key={column.id} style={{ textAlign: column.align }} className='table_body_content'>
                                                    {column.format && typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                    </tbody>
                </table >
            </InfiniteScroll >
            <FormDialog data={data} />
            {data.length > 0 && <KYCImage data={data} />}
        </>
    )
}

export default memo(UserTable)