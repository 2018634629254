import React, { useEffect, useState } from 'react'
import './office.scss'
import Search from '../../components/office/Search'
import SearchFinance from '../../components/finance/Search'
import OfficeTable from '../../components/office/OfficeTable'
import FinanceTable from '../../components/finance/FinanceTable'
import { useSelector } from 'react-redux'
import FormDialog from '../../components/finance/FinanceForm'

const Office = () => {
  const [financeId, setFinanceId] = useState(0)
  const { message } = useSelector(state => state.office)
  const [financeView, setFinanceView] = useState(false)

  const finance = (id) => {
    setFinanceId(id)
    setFinanceView(true)
  }


  useEffect(() => {
    setFinanceView(false)
    return () => {
      return
    }
  }, [message.length > 0])



  const hideFinanceTable = (value) => {
    setFinanceView(value)
  }

  return (
    <div className="office_container">
      <div className="office_container_left">
        <Search />
        <OfficeTable finance={finance} hideFinanceTable={hideFinanceTable} />
      </div>
      <div className="office_container_right">
        {
          (financeView && message.length < 1) &&
          <React.Fragment>
            <SearchFinance finance_id={financeId} />
            <FinanceTable />
          </React.Fragment>
        }
        <FormDialog />
      </div>
    </div>
  )
}

export default Office