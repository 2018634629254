import React, { memo, useState } from 'react'
import { useSelector } from 'react-redux';

const FileHeaderCellEditor = ({ label, index, onHeaderCellChange, color }) => {
    const [editing, setEditing] = useState(false);
    const { loadingHeader, fileHeader, defaultHeader, headerEditing } = useSelector(state => state.excel)

    // console.log(loadingHeader);
    const [values, setValues] = useState(label);
    const [colorValue, setColorValue] = useState(color)

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (event.target.value === '') {
                setValues(label)
                return (alert('Can not enter empty value'))
            } else {
                if (defaultHeader.includes(String(event.target.value).trim().toLowerCase().split('_').join(' '))) {
                    onHeaderCellChange(index, event.target.value, true);
                    setColorValue(true)
                } else {
                    onHeaderCellChange(index, event.target.value, false);
                    setColorValue(false)
                }
                setEditing(false);
            }
        }
    };



    const handleChange = (event) => {
        setValues(event.target.value);
    };


    if (editing) {
        return (
            <input
                style={{ width: '180px', margin: 0 }}
                type="text"
                value={values}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onBlur={() => setEditing(false)}
                autoFocus
            />
        );
    }
    return (<><div style={{ overflow: 'hidden', fontWeight: 400, fontSize: '13px' }}>{loadingHeader[index]}</div>
        <div onClick={() => headerEditing && setEditing(true)} style={{ overflow: 'hidden', fontWeight: 500, fontSize: '14px', background: `${colorValue && 'yellow'}` }}>{label}</div></>)
};

export default memo(FileHeaderCellEditor)