import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { searchVehicle, setSearchValue, setVehicleDetails } from '../../features/vehicle/vehicleSlice'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

const Search = () => {
    const { page_number, searchValue } = useSelector(state => state.vehicle)
    const dispatch = useDispatch()
    const dataSearchFunction = async (event) => {
        event.preventDefault()
        dispatch(setVehicleDetails({
            id: '',
            details: {}
        }))
        dispatch(searchVehicle({ searchValue, page_number: 1 }))
    }


    useEffect(() => {
        let fetching = false
        dispatch(setSearchValue({
            type: 1,
            value: ''
        }))
        dispatch(setVehicleDetails({
            id: '',
            details: {}
        }))
        dispatch(searchVehicle({ searchValue, page_number: 1 }))
        return () => {
            fetching = true
        }
    }, [])

    const inputSearchValue = (event) => {
        dispatch(setSearchValue({
            type: searchValue.type,
            value: event.target.value
        }))
        if (event.target.value === '') {
            dispatch(searchVehicle({
                searchValue: {
                    type: 0,
                    value: ''
                }, page_number: 1
            }))
        }
    }


    return (
        <div className='search_container'>
            <form onSubmit={dataSearchFunction} className='form_container'>
                <input className='form_input' placeholder={`Search ${searchValue.type === 0 ? 'in all' : searchValue.type === 1 ? 'by rc number' : searchValue.type === 2 ? 'by chassis number' : 'by engine number'}`} type="text" value={searchValue.value} onChange={inputSearchValue} />
                <input className='form_input' type="submit" value='Search' />
            </form>
            <FormControl sx={{ width: '40%' }}>
                <InputLabel id="demo-simple-select-label">Search By</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={searchValue.type}
                    label="Search By"
                    size='small'
                    onChange={(event) => {
                        dispatch(setSearchValue({ type: parseInt(event.target.value), value: '' }))
                        dispatch(setVehicleDetails({
                            id: '',
                            details: {}
                        }))
                    }}
                >
                    <MenuItem value={0}>All</MenuItem>
                    <MenuItem value={1}>RC Number</MenuItem>
                    <MenuItem value={2}>Chassis Number</MenuItem>
                    <MenuItem value={3}>Engine Number</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}

export default Search