import React from 'react'
import './signin.scss'
import SigInContent from '../../components/auth/SigIn'

const SignIn = () => {
    return (
        <div className="sign_in_container">
            <div className="sign_in_container_left">
                <SigInContent />
            </div>
        </div>
        // <div className="signin_container">
        // </div>
    )
}

export default SignIn