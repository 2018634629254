import React from 'react'
import './administrator.scss'
import Search from '../../components/administrator/Search'
import AdministratorTable from '../../components/administrator/AdministratorTable'
import AccessTable from '../../components/administrator/AccessTable'

const Administrator = () => {

    return (
        <div className="administrator_container">
            <div className="administrator_container_left">
                <Search />
                <AdministratorTable />
            </div>
            <div className="administrator_container_right">
                {/* <Search />
                <AccessTable /> */}
            </div>
        </div>
    )
}

export default Administrator