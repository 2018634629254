import React from 'react'
import './login.scss'
import LoginContent from '../../components/auth/Login'

const Login = () => {

    return (
        <div className="login_container">
            <LoginContent />
        </div>
    )
}

export default Login