import React from 'react'
import DetailsTable from '../../components/details/DetailsTable'
import './details.scss'
import Search from '../../components/details/Search'

const Details = () => {

    return (
        <div className="details_container">
            <div className="details_container_left">
                <Search />
                <DetailsTable />
            </div>
        </div>
    )
}

export default Details