import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API_URL } from '../../global/production_URL'
import axios from 'axios'


export const searchVehicle = createAsyncThunk(
    'vehicle/all',
    async ({ searchValue, page_number }) => {
        const options = {
            url: `${API_URL}vehicle/all?page=30&page_number=${page_number}`,
            method: 'POST',
            headers: {
                token: localStorage.getItem('token')
            },
            data: searchValue
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error.message
        }
    }
)




export const detailsVehicle = createAsyncThunk(
    'vehicle/details',
    async ({ vehicle_id }) => {
        const options = {
            url: `${API_URL}vehicle/details`,
            method: 'POST',
            headers: {
                token: localStorage.getItem('token')
            },
            data: { vehicle_id }
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error.message
        }
    }
)




export const deleteVehicle = createAsyncThunk(
    'vehicle/delete',
    async ({ vehicle_id }) => {
        const options = {
            url: `${API_URL}vehicle/delete/${vehicle_id}`,
            headers: {
                token: localStorage.getItem('token')
            },
            method: 'DELETE'
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error.message
        }
    }
)

const initialState = {
    totalVehicle: [],
    message: '',
    page_number: 1,
    totalSearchData: 0,
    searchValue: {
        type: 1,
        value: ''
    },
    vehicleDetails: {
        id: '',
        details: {}
    },
    updateMessage: {
        loading: '',
        message: ''
    },
    deleteMessage: {
        loading: '',
        message: ''
    },
}

export const vehicleSlice = createSlice({
    name: 'vehicle',
    initialState,
    reducers: {
        setUserData: (state, action) => {

        },
        setPageNumber: (state, action) => {
            state.page_number = action.payload
        },
        setSearchValue: (state, action) => {
            state.searchValue = action.payload
        },
        setVehicleDetails: (state, action) => {
            state.vehicleDetails = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(searchVehicle.pending, (state, action) => {
            if (action.meta.arg.page_number < 2)
                state.message = 'Please wait... We are fetching vehicle.'
        }).addCase(searchVehicle.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.message = ''
                if (action.meta.arg.page_number > 1) {
                    state.totalVehicle = state.totalVehicle.concat(action.payload.data.response)
                    state.page_number += 1
                } else {
                    state.page_number = 2
                    state.totalSearchData = action.payload.data.records[0].total
                    state.totalVehicle = action.payload.data.response
                }
            } else {
                if (action.meta.arg.page_number < 2)
                    state.message = 'Vehicle not found'
            }
        }).addCase(searchVehicle.rejected, (state, action) => {
            state.message = `Sorry We can't fetch data. Please try again`
        })





        builder.addCase(detailsVehicle.pending, (state, action) => {
            state.updateMessage = {
                loading: 'pending',
                message: 'Please wait... We are fetching vehicle details.'
            }
        }).addCase(detailsVehicle.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.updateMessage = {
                    loading: '',
                    message: ''
                }
                state.vehicleDetails = {
                    id: action.meta.arg.vehicle_id,
                    details: action.payload.data
                }
            } else {
                state.updateMessage = {
                    loading: 'fulfilled',
                    message: 'Vehicle details not found'
                }
            }
        }).addCase(detailsVehicle.rejected, (state, action) => {
            state.updateMessage = {
                loading: 'reject',
                message: `Sorry We can't fetch vehicle details. Please try again`
            }
        })




        builder.addCase(deleteVehicle.pending, (state, action) => {
            state.deleteMessage = {
                loading: 'pending',
                message: 'Please wait... We are delete this vehicle.'
            }
        }).addCase(deleteVehicle.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.deleteMessage = {
                    loading: '',
                    message: ''
                }
                state.vehicleDetails = {
                    id: '',
                    details: {}
                }
                state.totalVehicle = state.totalVehicle.filter(vehicle => vehicle.vehicle_id !== action.meta.arg.vehicle_id)
                state.totalSearchData -= 1
            } else {
                state.deleteMessage = {
                    loading: 'fulfilled',
                    message: `Vehicle didn't delete `
                }
            }
        }).addCase(deleteVehicle.rejected, (state, action) => {
            state.deleteMessage = {
                loading: 'reject',
                message: `Sorry We can't delete this vehicle. Please try again`
            }
        })
    }
})

// Action creators are generated for each case reducer function
export const { setPageNumber, setUserData, setSearchValue, setVehicleDetails } = vehicleSlice.actions

export default vehicleSlice.reducer