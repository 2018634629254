import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as XLSX from 'xlsx'
import { API_URL } from '../../global/production_URL';
import axios from 'axios';
import { Autocomplete, Button, TextField } from '@mui/material';
import { setBackdrop, setBranchId, uploadExcelFileData } from '../../features/excel/excelSlice';




const BranchSelector = () => {
    const { totalFinance } = useSelector(state => state.excel)
    const dispatch = useDispatch()
    const [userBranch, setUserBranch] = useState()
    useEffect(() => {
        if (userBranch === '' || userBranch === undefined) {
            dispatch(setBranchId(null))
        } else {
            dispatch(setBranchId(userBranch.id))
        }
    }, [userBranch])


    const options = totalFinance.map((value) => {
        return { label: value.name, id: value.id }
    })


    return (
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            className='user_btn'
            options={options}
            value={userBranch}
            size='small'
            onChange={(event, newValue) => {
                if (newValue !== null) {
                    setUserBranch(newValue)
                } else {
                    setUserBranch('')
                }
            }}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField size='small' {...params} label="Select a branch" />}
        />
    );
}







const FileUpload = () => {
    const { fileHeader, validHeaderIndex, verifiedData, loadingHeader, branchId } = useSelector(state => state.excel)
    const dispatch = useDispatch()



    // ,contract,contractno,contractid, agreementid, aggreementno,dealno, contrno,agreementno,loanno,loan,agreement,applicationid,accountno,fileno, fileid


    const upload = async () => {

        if (branchId === null || branchId === '') {
            return alert('Please select a branch')
        }


        if (verifiedData.length < 1) {
            return alert('Please verify data')
        }
        dispatch(setBackdrop(true))

        Promise.all(validHeaderIndex.map(async (value) => {
            const option = {
                url: `${API_URL}header/update`,
                method: 'PUT',
                data: {
                    oldHeader: String(fileHeader[parseInt(value)].label).trim().toLowerCase().split(' ').join('_'),
                    value: String(loadingHeader[parseInt(value)]).trim().replace(/\s+/g, '').toLowerCase().split('_').join('')
                },
                headers: {
                    token: localStorage.getItem('token')
                }
            }
            try {
                await axios(option)
            } catch (error) {
                return error.message
            }
        }))
        // let finance_id = 2

        const wb = await XLSX.utils.book_new();
        const ws = await XLSX.utils.json_to_sheet([]);
        // const newArrs = new Array(commonElements)
        // await XLSX.utils.sheet_add_aoa(ws, newArrs);
        await XLSX.utils.sheet_add_json(ws, verifiedData);
        // XLSX.utils.sheet_add_json(ws, updateArray, { origin: 'A2', skipHeader: true });
        await XLSX.utils.book_append_sheet(wb, ws, 'Report');
        const buffer = await XLSX.write(wb, { type: 'string', bookType: 'csv' });
        // return buffer;
        const formData = new FormData();
        formData.append('file', new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
        formData.append('data', JSON.stringify({ branchId }));
        const action = await dispatch(uploadExcelFileData({ formData }))
        if (action.payload.status === 200) {
            alert('success')
        } else {
            alert('Internal server error')
        }
        dispatch(setBackdrop(false))
    }

    return (
        <>
            <BranchSelector />
            <Button color='secondary' variant='contained' size='small' sx={{ marginLeft: '10px' }} onClick={upload}>Upload</Button>
        </>
    )
}

export default memo(FileUpload)