import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from './layout/mainLayout/MainLayout';
import User from './pages/user/User';
import Office from './pages/office/Office';
import Vehicle from './pages/vehicle/Vehicle';
import Dashboard from './pages/dashboard/Dashboard';
import Details from './pages/details/Details';
import Administrator from './pages/administrator/Administrator';
import LoginLayout from './layout/loginLayout/LoginLayout';
import Login from './pages/auth/Login';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { allConfirmVehicle, allOffice, allUser, allVehicle } from './features/records/recordSlice';
import { RefreshLogin, setLoggedIn } from './features/auth/authSlice';
import SignIn from './pages/auth/SignIn';
import { Paper, Typography } from '@mui/material';
import FileUpload from './pages/file/FileUpload';
import { allFinance, allHeader } from './features/excel/excelSlice';

function App() {
  const dispatch = useDispatch()
  const { loggedIn, isAdmin } = useSelector(state => state.auth)
  const [isConnected, setIsConnected] = useState(navigator.onLine);

  useEffect(() => {
    let fetching = false
    if (loggedIn) {
      Promise.all([
        dispatch(allFinance()),
        dispatch(allVehicle()),
        dispatch(allOffice()),
        dispatch(allConfirmVehicle({ confirm: 1 })),
        dispatch(allConfirmVehicle({ confirm: 0 })),
        dispatch(allUser({ confirm: 0 })),
        dispatch(allUser({ confirm: 1 })),
        dispatch(allUser({ confirm: 2 })),
        dispatch(allHeader())
      ])
    }

    return () => {
      return fetching = true
    }
  }, [loggedIn])


  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsConnected(navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, [window]);


  useEffect(() => {
    let fetching = false;

    let value = localStorage.getItem('token')
    if (value === null) {
      dispatch(setLoggedIn(false))
    } else {
      dispatch(RefreshLogin())
    }

    return () => {
      return fetching = true
    }
  }, [])

  // console.log(localStorage.getItem('token'));

  return (
    isConnected ? <BrowserRouter>
      <Routes>
        <Route path='/' element={loggedIn ? <MainLayout /> : <Navigate to='/administrator/auth'></Navigate>}>
          <Route index element={<Dashboard />} />
          <Route path='user' element={<User />}></Route>
          <Route path='head-office' element={<Office />}></Route>
          <Route path='file-upload' element={<FileUpload />}></Route>
          <Route path='vehicle' element={<Vehicle />}></Route>
          {isAdmin && <Route path='administrator' element={<Administrator />}></Route>}
          <Route path='details' element={<Details />}></Route>
          {isAdmin && <Route path='administrator/auth/register' element={<SignIn />} />}
        </Route>
        {
          !loggedIn && <Route path='/administrator/auth' element={<LoginLayout />}>
            <Route index element={<Login />} />
          </Route>
        }
        <Route path='*' element={<Navigate to='/'></Navigate>} />
      </Routes>
       
    </BrowserRouter> : <Paper sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Typography variant='h5'>Please connect with your internet connection</Typography>
    </Paper>
  );
}

export default App;
