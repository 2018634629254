import React, { useState } from 'react'
import './auth.scss'
import isMobilePhone from 'validator/lib/isMobilePhone'
import isStrongPassword from 'validator/lib/isStrongPassword'
import isEmpty from 'validator/lib/isEmpty'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Box, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material'
import { login, register, setRegisterMessage } from '../../features/auth/authSlice'
import { useNavigate } from 'react-router-dom'


const initialState = {
    mobile: '',
    password: '',
    name: '',
    type: 2
}

const SigInContent = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { message, registerMessage } = useSelector(state => state.auth)
    const [signUpData, setSignUnData] = useState(initialState)
    const [errorType, setErrorType] = useState({
        type: '',
        message: ''
    })

    const onChange = (event) => {
        let { name, value } = event.target
        setSignUnData({
            ...signUpData,
            [name]: value
        })
    }


    const onSubmit = async (event) => {
        event.preventDefault()
        let password = false;
        let mobile = false


        if (signUpData.mobile.length !== 10) {
            return setErrorType({
                name: 'mobile',
                message: 'Please enter only 10 digits mobile number'
            })
        }


        if (isMobilePhone(String(signUpData.mobile), 'en-IN')) {
            mobile = true
        } else {
            mobile = false
            return setErrorType({
                name: 'mobile',
                message: 'Enter valid mobile number'
            })
        }

        if (signUpData.password.length < 8) {
            return setErrorType({
                name: 'password',
                message: 'Password length should be minimum 8'
            })
        }


        if (isStrongPassword(signUpData.password)) {
            password = true
        } else {
            password = false
            return setErrorType({
                name: 'password',
                message: 'Enter valid password'
            })
        }

        setErrorType({
            name: '',
            message: ''
        })


        if (mobile && password) {
            const action = await dispatch(register({ data: signUpData }))
            if (action.payload.status === 200) {
                setSignUnData(initialState)
                setTimeout(() => {
                    dispatch(setRegisterMessage())
                }, 2000);
            }
        }
    }

    return (
        <div className="login_wrapper">
            {
                registerMessage.message.length > 0 && <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert sx={{ display: 'flex', justifyContent: 'center', mb: 3 }} severity={registerMessage.loading === 'pending' ? 'info' : registerMessage.loading === 'success' ? 'success' : 'error'}>{registerMessage.message}</Alert>
                </Stack>
            }
            <h5 className="title">New here?</h5>
            <p className="sub_title">Signing up is easy. It only takes a few steps</p>
            <Box
                component='form'
                autoComplete='off'
                onSubmit={onSubmit}
                sx={{
                    '& > :not(style)': { m: 1 },
                    mt:3
                }}
            >
                <TextField required className='input_field' autoComplete='off' fullWidth value={signUpData.name} onChange={onChange} name='name' placeholder='Enter name' />
                <TextField required helperText={errorType.name === 'mobile' && errorType.message} error={errorType.name === 'mobile'} className='input_field' autoComplete='off' fullWidth type='number' value={signUpData.mobile} onChange={onChange} name='mobile' placeholder='Enter mobile number' />
                <FormControl fullWidth>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={signUpData.type}
                        size='medium'
                        name='type'
                        onChange={onChange}
                        className='input_field'
                    >
                        <MenuItem value={1}>Admin</MenuItem>
                        <MenuItem value={2}>Sub-Admin</MenuItem>
                    </Select>
                </FormControl>
                <TextField required helperText={errorType.name === 'password' && errorType.message} error={errorType.name === 'password'} autoComplete='off' className='input_field' fullWidth type='password' value={String(signUpData.password).trim().split(' ').join('')} onChange={onChange} name='password' placeholder='Enter password' />
                <TextField autoComplete='off' className='input_field' fullWidth value={signUpData.password} placeholder='Your password' />
                <TextField autoComplete='off' type='submit' onSubmit={onSubmit} role='button' fullWidth value='Register' />
            </Box>
            {
                message.message.length > 0 && <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert sx={{ display: 'flex', justifyContent: 'center' }} severity={message.loading === 'pending' ? 'info' : 'error'}>{message.message}</Alert>
                </Stack>
            }
        </div>
    )
}

export default SigInContent