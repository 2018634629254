import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API_URL } from '../../global/production_URL'
import axios from 'axios'


export const vehicleConfirmRequest = createAsyncThunk(
    'dashboard/vehicle/confirm/request',
    async ({ data }) => {
        const options = {
            url: `${API_URL}vehicle/confirm/all`,
            method: 'POST',
            headers: {
                token: localStorage.getItem('token')
            }
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error.message
        }
    }
)


export const vehicleConfirmAction = createAsyncThunk(
    'dashboard/vehicle/confirm/action',
    async ({ id, data }) => {
        const options = {
            url: `${API_URL}vehicle/confirm/vehicle/${id}`,
            method: 'PUT',
            data: data,
            headers: {
                token: localStorage.getItem('token')
            }
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error.message
        }
    }
)



const initialState = {
    totalConfirmVehicleByUser: [],
    message: '',
    page_number: 1,
    totalSearchData: 0,
    searchValue: '',
    data: [],
    updateMessage: {
        loading: '',
        message: ''
    },
    vehicleDetails: [],
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload
        },
        setPageNumber: (state, action) => {
            state.page_number = action.payload
        },
        setSearchValue: (state, action) => {
            state.searchValue = action.payload
        },
        setUpdateMessage: (state, action) => {
            state.updateMessage = action.payload
        },
        setVehicleDetails: (state, action) => {
            state.vehicleDetails = state.totalConfirmVehicleByUser.filter(vehicle => vehicle.vehicle_id === action.payload)
        },
        setVehicleDetailsSubmit: (state, action) => {
            state.vehicleDetails = []
        }
    },
    extraReducers: builder => {
        builder.addCase(vehicleConfirmRequest.pending, (state, action) => {
            if (action.meta.arg.page_number < 2)
                state.message = 'Please wait... We are confirmation vehicle data.'
        }).addCase(vehicleConfirmRequest.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.message = ''
                if (action.meta.arg.page_number > 1) {
                    state.totalConfirmVehicleByUser = state.totalConfirmVehicleByUser.concat(action.payload.data.response)
                    state.page_number += 1
                } else {
                    state.page_number = 2
                    state.totalSearchData = action.payload.data.total
                    state.totalConfirmVehicleByUser = action.payload.data.response
                }
            } else {
                state.message = 'Vehicle not found for confirmation'
            }
        }).addCase(vehicleConfirmRequest.rejected, (state, action) => {
            state.message = `Sorry We can't fetch data. Please try again`
        })



        builder.addCase(vehicleConfirmAction.pending, (state, action) => {
            state.updateMessage = {
                loading: 'pending',
                message: 'Please wait... We are accepting you request.'
            }
        }).addCase(vehicleConfirmAction.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.updateMessage = {
                    loading: '',
                    message: ''
                }
                state.totalConfirmVehicleByUser = state.totalConfirmVehicleByUser.filter(vehicle => vehicle.vehicle_id !== action.meta.arg.data.vehicle_id)
                state.totalSearchData -= 1
            } else {
                state.updateMessage = {
                    loading: 'fulfilled',
                    message: `Sorry we can't accept you request.`
                }
            }
        }).addCase(vehicleConfirmAction.rejected, (state, action) => {
            state.updateMessage = {
                loading: 'reject',
                message: `Sorry we can't accept you request. Please try again`
            }
        })
    }
})

// Action creators are generated for each case reducer function
export const { setPageNumber, setData, setSearchValue, setUpdateMessage, setAlertOpenOrClose, setVehicleDetails, setVehicleDetailsSubmit } = dashboardSlice.actions

export default dashboardSlice.reducer