import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { allOffice, setData, setSearchValue } from '../../features/office/officeSlice'
import { AddRounded } from '@mui/icons-material'
import { setFormOpenOrClose } from '../../features/common/commonSlice'

const AccessSearch = () => {
    const { page_number, searchValue } = useSelector(state => state.office)
    const dispatch = useDispatch()
    const dataSearchFunction = async (event) => {
        event.preventDefault()
        dispatch(allOffice({ searchValue, page_number: 1 }))
    }

    useEffect(() => {
        let fetching = false
        dispatch(setSearchValue(''))
        dispatch(allOffice({ searchValue: '', page_number: 1 }))
        return () => {
            fetching = true
        }
    }, [])

    const inputSearchValue = (event) => {
        dispatch(setSearchValue(event.target.value))
        if (event.target.value === '') {
            dispatch(allOffice({ searchValue: '', page_number: 1 }))
        }
    }

    return (
        <div className='search_container'>
            <form onSubmit={dataSearchFunction} className='form_container'>
                <input className='form_input' placeholder='Search Head Office' type="text" value={searchValue} onChange={inputSearchValue} />
                <input className='form_input' type="submit" value='Search' />
            </form>
        </div>
    )
}

export default memo(AccessSearch)