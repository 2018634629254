import { configureStore } from '@reduxjs/toolkit'
import userReducer from '../features/user/userSlice'
import commonReducer from '../features/common/commonSlice'
import officeReducer from '../features/office/officeSlice'
import financeReducer from '../features/finance/financeSlice'
import vehicleReducer from '../features/vehicle/vehicleSlice'
import detailsReducer from '../features/details/detailsSlice'
import adminReducer from '../features/admin/adminSlice'
import authReducer from '../features/auth/authSlice'
import recordReducer from '../features/records/recordSlice'
import dashboardReducer from '../features/dashboard/dashboardSlice'
import excelReducer from '../features/excel/excelSlice'

export const store = configureStore({
  reducer: {
    user: userReducer,
    common: commonReducer,
    office: officeReducer,
    finance: financeReducer,
    vehicle: vehicleReducer,
    details: detailsReducer,
    admin: adminReducer,
    auth: authReducer,
    record: recordReducer,
    dashboard: dashboardReducer,
    excel: excelReducer
  }
})