import React from 'react'
import Search from '../../components/user/Search'
import UserTable from '../../components/user/UserTable'
import './user.scss'
import AccessTable from '../../components/access/AccessTable'
import AccessSearch from '../../components/access/Search'
import { useSelector } from 'react-redux'

const User = () => {

    const { userAccessData } = useSelector(state => state.user)

    return (
        <div className="user_container">
            <div className="user_container_left">
                <Search />
                <UserTable />
            </div>
            <div className="user_container_right">
                {userAccessData.length > 0 && <>
                    <AccessSearch />
                    <AccessTable />
                </>}
            </div>
        </div>
    )
}

export default User