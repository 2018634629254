import React, { useEffect } from 'react'
import './dashboard.scss'
import RequestTable from '../../components/request/RequestTable'
import Search from '../../components/request/Search'
import { useDispatch, useSelector } from 'react-redux'
import VehicleConfirmRequestTable from '../../components/confirmRequest/VehicleConfirmRequestTable'
import VehicleRequestSearch from '../../components/confirmRequest/Search'
import { Button } from '@mui/material'
import { allConfirmVehicle, allOffice, allUser, allVehicle } from '../../features/records/recordSlice'

const Dashboard = () => {
    const { totalVehicle, totalVehicleConfirm, totalVehicleConfirmPending, totalConfirmUser, totalRejectUser, totalRequestUser, totalOffice } = useSelector(state => state.record)
    const { totalFinance } = useSelector(state => state.excel)
    const { isAdmin } = useSelector(state => state.auth)
    const dispatch = useDispatch()

    // useEffect(() => {
    //     window.onbeforeunload = 
    // }, [])



    return (
        <>
            <Button sx={{ ml: 4 }} variant='contained' size='small' onClick={() => {
                Promise.all([
                    dispatch(allVehicle()),
                    dispatch(allOffice()),
                    dispatch(allConfirmVehicle({ confirm: 1 })),
                    dispatch(allConfirmVehicle({ confirm: 0 })),
                    dispatch(allUser({ confirm: 0 })),
                    dispatch(allUser({ confirm: 1 })),
                    dispatch(allUser({ confirm: 2 }))
                ])
            }}>Refresh</Button>
            <div className="dashboard_container">
                {/* <button onClick={() => console.log(window.location.reload(true))}>Refresh</button> */}
                <div className="dashboard_wrapper">
                    <div className="dashboard_container_right">
                        <div className="badge_container">
                            <div className="badge_wrapper" style={{ background: '#7978e9' }}>
                                <p className='title'>Total Finance</p>
                                <h3 className='content'>{totalFinance.length}</h3>
                            </div>
                            <div className="badge_wrapper" style={{ background: '#f3797e' }}>
                                <p className='title'>Total Rejected</p>
                                <h3 className='content'>{totalRejectUser}</h3>
                                {/* <p className='inc'>10.00% (30 days)</p> */}
                            </div>
                        </div>
                        <div className="badge_container">
                            <div className="badge_wrapper">
                                <p className='title'>Total Confirm User</p>
                                <h3 className='content'>{totalConfirmUser}</h3>
                                {/* <p className='inc'>10.00% (30 days)</p> */}
                            </div>
                            <div className="badge_wrapper" style={{ background: '#4747a1' }}>
                                <p className='title'>Total Head Office</p>
                                <h3 className='content'>{totalOffice}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard_container_right">
                        <div className="badge_container">
                            <div className="badge_wrapper">
                                <p className='title'>Total Vehicles</p>
                                <h3 className='content'>{totalVehicle}</h3>
                                {/* <p className='inc'>10.00% (30 days)</p> */}
                            </div>
                            <div className="badge_wrapper" style={{ background: '#4747a1' }}>
                                <p className='title'>Total Confirm Vehicles</p>
                                <h3 className='content'>{totalVehicleConfirm}</h3>
                                <p className='inc'>(30 days)</p>
                            </div>
                        </div>
                        <div className="badge_container">
                            <div className="badge_wrapper" style={{ background: '#7978e9' }}>
                                <p className='title'>Vehicle Confirmation Pending...</p>
                                <h3 className='content'>{totalVehicleConfirmPending}</h3>
                                <p className='inc'>(30 days)</p>
                            </div>
                            <div className="badge_wrapper">
                                <p className='title'>Total Users</p>
                                <h3 className='content'>{totalConfirmUser + totalRejectUser + totalRequestUser}</h3>
                                {/* <p className='inc'>10.00% (30 days)</p> */}
                            </div>

                        </div>
                    </div>
                </div>
                <div className="dashboard_table_container">
                    {
                        isAdmin && <div className="dashboard_container_left left">
                            <Search />
                            <RequestTable />
                        </div>
                    }
                    <div className={`dashboard_container_right right ${!isAdmin && 'show'}`}>
                        <VehicleRequestSearch />
                        <VehicleConfirmRequestTable />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard