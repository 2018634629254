import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { CellMeasurerCache } from 'react-virtualized';
import axios from 'axios';
import { API_URL } from '../../global/production_URL';


export const uploadExcelFileData = createAsyncThunk(
    'excel/data/upload',
    async ({ formData }) => {
        const options = {
            url: `${API_URL}vehicle/upload`,
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data',
                token: localStorage.getItem('token')
            },
            data: formData
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error
        }
    }
)





// ######################----GETTING ALL HEADER FUNCTION FROM SERVER----#############----START----###########################
export const allHeader = createAsyncThunk(
    'header/get/all',
    async () => {
        const option = {
            url: `${API_URL}header/all`,
            method: 'POST',
            headers: {
                token: localStorage.getItem('token')
            }
        }
        try {
            const response = await axios(option)
            return response
        } catch (error) {
            return error.message
        }
    }
)
// ######################----GETTING ALL HEADER FUNCTION FROM SERVER----#############----END----#############################




export const allFinance = createAsyncThunk(
    'excel/branch/all',
    async () => {
        const options = {
            url: `${API_URL}finance/upload/all`,
            method: 'POST',
            headers: {
                token: localStorage.getItem('token')
            }
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error
        }
    }
)



export const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 40,
});


const initialState = {
    defaultHeader: ["contract no", "rc number", "mek and model", "area", "region", "branch", "customer name", "financer", "engine number", "chassis number", "ex name", "level1", "level2", "level3", "level4", "level1con", "level2con", "level3con", "level4con", "bkt", "od", "gv", "ses9", "ses17", "tbr", "poss"],
    fileHeader: [],
    loadingHeader: [],
    fileData: [],
    databaseHeader: [],
    backdrop: false,
    verifiedData: [],
    validHeaderIndex: [],
    branchId: null,
    headerEditing: true,
    totalFinance: []
}

export const excelSlice = createSlice({
    name: 'excel',
    initialState,
    reducers: {
        setFileLoadingHeader: (state, action) => {
            state.fileHeader = []
            cache.clearAll()
            state.fileHeader = Object.keys(action.payload).map(header => {
                return { label: header, dataKey: header, color: false }
            })
            state.loadingHeader = Object.keys(action.payload)



            // if (state.databaseHeader.length > 0) {
            for (let i = 0; i < state.databaseHeader.length; i++) {
                let invalidHeader = state.databaseHeader[i].initialheader.split(',').filter(Boolean).map(value => String(value).trim())
                const validHeader = state.databaseHeader[i].headername.split("_").join(" ")

                state.fileHeader = state.fileHeader.map((header, index) => {
                    if (invalidHeader.includes(String(header.label).trim().toLowerCase().replace(/\s+/g, '').split(/[-:._,' ']/).join(''))) {
                        return { ...header, label: validHeader, color: true }
                    } else {
                        return { ...header }
                    }
                })
            }
            state.backdrop = false
        },
        setFileData: (state, action) => {
            cache.clearAll()
            state.fileData = []
            state.fileData = action.payload
        },
        setFileHeader: (state, action) => {
            cache.clearAll()
            state.fileHeader = action.payload
        },
        setUpdatedHeader: (state, action) => {
            state.fileHeader.map((header, index) => {
                if (index === action.payload.index) {
                    return { ...header, label: action.payload.value, color: true }
                } else {
                    return header
                }
            })
        },
        setBackdrop: (state, action) => {
            state.backdrop = action.payload
        },
        setVerifiedData: (state, action) => {
            state.verifiedData = state.verifiedData.concat([...action.payload])
        },
        setInitialValue: (state, action) => {
            state.fileHeader = []
            state.loadingHeader = []
            state.fileData = []
            state.backdrop = false
            state.verifiedData = []
        },
        setValidHeaderIndex: (state, action) => {
            state.validHeaderIndex = action.payload
        },
        setVerifyHeader: (state, action) => {
            state.fileHeader = []
            cache.clearAll()
            state.fileHeader = Object.keys(action.payload).map((header, index) => {
                return { label: header, dataKey: header, color: false }
            })
            state.loadingHeader = Object.keys(action.payload)
            state.backdrop = false
        },
        setBranchId: (state, action) => {
            state.branchId = action.payload
        },
        setHeaderEditing: (state, action) => {
            state.headerEditing = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(allHeader.fulfilled, (state, action) => {
            if (action.payload.status === 200 && action.payload.data.success === false) {
            } else if (action.payload.status === 200) {
                state.databaseHeader = action.payload.data
            } else {
                // console.log(action.payload);
            }
        })


        builder.addCase(allFinance.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.totalFinance = action.payload.data
            }
        })


        builder.addCase(uploadExcelFileData.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.fileHeader = []
                state.loadingHeader = []
                state.fileData = []
                state.backdrop = false
                state.verifiedData = []
                state.validHeaderIndex = []
                state.branchId = null
                state.headerEditing = true
            }
        })
    }
})

// Action creators are generated for each case reducer function
export const { setFileLoadingHeader, setFileHeader, setFileData, setUpdatedHeader, setBackdrop, setVerifiedData, setInitialValue, setValidHeaderIndex, setBranchId, setHeaderEditing } = excelSlice.actions

export default excelSlice.reducer