import { Box, LinearProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React, { memo, useCallback, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux'
import { allUser } from '../../features/user/userSlice';
import { setFormOpenOrClose } from '../../features/common/commonSlice';
import './office.scss'
import FormDialog from './OfficeForm';
import { allOffice, setHeadOffice, setData as setOfficeData } from '../../features/office/officeSlice';
import { setData } from '../../features/finance/financeSlice';




const createData = (name, finance, action) => {
    return { name, finance, action };
}



const columns = [
    { id: 'name', label: 'Head Office Name', minWidth: 200, align: 'start',fontWeight: 500 },
    { id: 'finance', label: 'Finance', minWidth: 30, align: 'center', },
    { id: 'action', label: 'Action', minWidth: 100, align: 'center' },
];



const FinanceTable = ({ finance, hideFinanceTable }) => {
    const { totalOffice, message, totalSearchData, searchValue, page_number } = useSelector(state => state.office)
    const { isAdmin } = useSelector(state => state.auth)
    const dispatch = useDispatch()


    const officeDetails = useCallback((value) => {
        const values = totalOffice.filter(user => user.id === value)
        dispatch(setOfficeData(values))
        dispatch(setFormOpenOrClose({ type: 'office', open: true }))
        return
    })



    const rows = useMemo(() => {
        return totalOffice.map((office) => {
            return createData(office.name, office.total_branch, office.id)
        });
    }, [totalOffice])


    return (
        <>
            {
                message.length !== 0 ? <Typography sx={{ mt: 1, p: 2, textAlign: 'center' }} component={Paper}>
                    {message}
                </Typography> :
                    <InfiniteScroll
                        dataLength={totalOffice.length}
                        className='infinite_scroll_office'
                        next={() => {
                            dispatch(allOffice({ searchValue: searchValue, page_number: page_number }))
                        }}
                        height='calc(100vh - 120px)'
                        hasMore={totalOffice.length < totalSearchData}
                        loader={
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                        }
                    >
                        <table className='table_container'>
                            <thead sx={{ height: '10px' }} className='table_head_wrapper'>
                                <tr className='table_content_wrapper'>
                                    {columns.map((column) => (
                                        <th
                                            sx={{ border: '.2px solid lightGray', minWidth: column.minWidth }}
                                            className='table_row'
                                            size='small'
                                            key={column.id}
                                            style={{ textAlign: column.align }}
                                        >
                                            {column.label}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className='table_body_wrapper'>
                                {rows
                                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <tr className='table_body_content_wrapper' key={row.id} onClick={(event) => {
                                                event.stopPropagation()
                                                if (isAdmin)
                                                    officeDetails(row.action)
                                            }} >
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    if (column.id === 'action') {
                                                        return (
                                                            <td style={{ textAlign: column.align }} key={column.id} className='table_body_content'>
                                                                <button disabled={parseInt(row.finance) === 0} className={`user_btn ${parseInt(row.finance) === 0 && 'disable'}`} size='small' sx={{ marginLeft: '5px' }} onClick={(event) => {
                                                                    event.stopPropagation()
                                                                    finance(row[column.id])
                                                                    dispatch(setHeadOffice({ name: row.name, id: row.action }))
                                                                }}>View</button>
                                                                {isAdmin &&
                                                                    <button className='user_btn' color='success' size='small' sx={{ marginLeft: '5px' }} onClick={(event) => {
                                                                        event.stopPropagation()
                                                                        dispatch(setData([]))
                                                                        hideFinanceTable(false)
                                                                        dispatch(setHeadOffice({ name: row.name, id: row.action }))
                                                                        dispatch(setFormOpenOrClose({ type: 'finance', open: true }))
                                                                    }}>Add</button>
                                                                }
                                                            </td>
                                                        );
                                                    }
                                                    return (
                                                        <td key={column.id} style={{ textAlign: column.align, fontWeight: column.fontWeight }} className='table_body_content'>
                                                            {column.format && typeof value === 'number'
                                                                ? column.format(value)
                                                                : value}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table >
                    </InfiniteScroll >
            }
            <FormDialog />
        </>
    )
}

export default memo(FinanceTable)