import React, { useState } from 'react'
import './auth.scss'
import isMobilePhone from 'validator/lib/isMobilePhone'
import isStrongPassword from 'validator/lib/isStrongPassword'
import isEmpty from 'validator/lib/isEmpty'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Box, Stack, TextField } from '@mui/material'
import { login } from '../../features/auth/authSlice'
import { useNavigate } from 'react-router-dom'



const LoginContent = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { message } = useSelector(state => state.auth)
    const [loginData, setLoginData] = useState({
        mobile: '',
        user_password: ''
    })
    const [errorType, setErrorType] = useState({
        type: '',
        message: ''
    })

    const onChange = (event) => {
        let { name, value } = event.target
        setLoginData({
            ...loginData,
            [name]: value
        })
    }


    const onSubmit = async (event) => {
        event.preventDefault()
        let password = false;
        let mobile = false

        if (isEmpty(loginData.mobile)) {
            return setErrorType({
                name: 'mobile',
                message: 'Enter you mobile number'
            })
        }
        if (isEmpty(loginData.user_password)) {
            return setErrorType({
                name: 'password',
                message: 'Enter you password'
            })
        }

        if (loginData.mobile.length !== 10) {
            return setErrorType({
                name: 'mobile',
                message: 'Please enter only 10 digits mobile number'
            })
        }


        if (isMobilePhone(loginData.mobile, 'en-IN')) {
            mobile = true
        } else {
            mobile = false
            return setErrorType({
                name: 'mobile',
                message: 'Enter valid mobile number'
            })
        }

        if (loginData.user_password.length < 8) {
            return setErrorType({
                name: 'password',
                message: 'Password length should be minimum 8'
            })
        }


        if (isStrongPassword(loginData.user_password)) {
            password = true
        } else {
            password = false
            return setErrorType({
                name: 'password',
                message: 'Enter valid password'
            })
        }

        setErrorType({
            name: '',
            message: ''
        })


        if (mobile && password) {
            const action = await dispatch(login({ data: loginData }))
            if (action.payload.status === 200) {
                navigate('/')
            }
        }
    }

    return (
        <div className="login_wrapper">
             {
                message.message.length > 0 && <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert sx={{ display: 'flex', justifyContent: 'center' , mb:3}} severity={message.loading === 'pending' ? 'info' : 'error'}>{message.message}</Alert>
                </Stack>
            }
            <h5 className="title">Hello! let's get started</h5>
            <p className="sub_title">Sign in to continue.</p>
            <Box component='form'   sx={{
                    '& > :not(style)': { m: 1 },
                    mt:3
                }} autoComplete='off' onSubmit={onSubmit}>
                <TextField required type='number' helperText={errorType.name === 'mobile' && errorType.message} error={errorType.name === 'mobile'} fullWidth placeholder='Enter mobile number' autoComplete='off' value={loginData.mobile} name='mobile' onChange={onChange}></TextField>
                <TextField required helperText={errorType.name === 'password' && errorType.message} error={errorType.name === 'password'} type='password' fullWidth placeholder='Enter password' autoComplete='off' value={loginData.user_password} name='user_password' onChange={onChange}></TextField>
                <TextField fullWidth onSubmit={onSubmit} value='Sign In' role='button' type='submit'></TextField>
            </Box>
            {/* <form className='auth_form' onSubmit={onSubmit}>
                <input type='text' value={loginData.mobile} onChange={onChange} name='mobile' placeholder='Enter your mobile number'></input>
                <p className='error'>{errorType.name === 'mobile' && errorType.message}</p>
                <input type='password' value={loginData.user_password} onChange={onChange} name='user_password' placeholder='Enter your password'></input>
                <p className='error'>{errorType.name === 'password' && errorType.message}</p>
                <input className='submit' type='submit' value='SIGN IN'></input>
            </form> */}
            <a className="forgot_pass">Forgot password?</a>
        </div>
    )
}

export default LoginContent