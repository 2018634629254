import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { passwordValidation, serverDateString, setFormOpenOrClose } from '../../features/common/commonSlice';
import { Alert, Box, FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack } from '@mui/material';
import './user.scss'
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateField, DatePicker, DesktopDatePicker } from '@mui/x-date-pickers';
import { updateUserDetails, userPlanUpdate } from '../../features/user/userSlice';


const initialState = {
    id: '',
    name: '',
    address: '',
    mobile: '',
    is_active: 1,
    type: 0,
    balance: 0,
    password: '',
}


const handleCalculateDays = (event) => {
    const start = Date.now();
    const end = new Date(event);
    const diff = Math.abs(end - start);
    if (end < Date.now()) {
        return 0
    } else {
        const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
        return days
    }
}


const planInitialState = {
    access_from: Date.now(),
    access_to: Date.now(),
}

const FormDialog = ({ data }) => {
    const { updateMessage, updatePlanMessage, updateUserMessage } = useSelector(state => state.user)
    const [userData, setUserData] = React.useState(initialState)
    const [userPlanData, setUserPlanData] = React.useState(planInitialState)
    const [userError, setUserError] = React.useState({
        type: false,
        field: '',
        message: ''
    })

    React.useEffect(() => {
        setUserError({
            type: false,
            field: '',
            message: ''
        })
        if (data.length > 0) {
            setUserData({
                id: data[0].id,
                name: data[0].name,
                address: data[0].address,
                mobile: data[0].mobile,
                is_active: data[0].is_active,
                type: data[0].type,
                balance: data[0].balance,
                password: data[0].password
            })
            setUserPlanData({
                access_from: data[0].access_from,
                access_to: data[0].access_to,
            })
        } else {
            setUserData(initialState)
        }
    }, [data])

    const formOpenOrClose = useSelector(state => state.common.formOpenOrClose)
    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(setFormOpenOrClose({ type: 'user', open: false }))
    };



    const onChange = (event) => {
        const { value, name } = event.target

        setUserData({
            ...userData,
            [name]: value
        })
    }


    const onPlanChange = (event) => {
        setUserPlanData({
            access_from: serverDateString(Date.now()),
            access_to: serverDateString(event.$d)
        })
    }

    const updateUserData = async () => {
        let passwordValid = true
        console.log(userData.password === null);

        if (userData.name === null || userData.name === '') {
            return setUserError({
                type: true,
                field: 'name',
                message: `Name can't empty`
            })
        }
        if (userData.address === null || userData.address === '') {
            return setUserError({
                type: true,
                field: 'address',
                message: `Address can't empty`
            })
        }

        if (parseInt(userData.type) > 0) {
            passwordValid = passwordValidation(String(userData.password))
        }
        if (!passwordValid) {
            return setUserError({
                type: true,
                field: 'password',
                message: 'You have entered invalid password'
            })
        }

        setUserError({
            type: false,
            field: '',
            message: ''
        })


        const data = {
            name: userData.name,
            address: userData.address,
            mobile: userData.mobile,
            is_active: parseInt(userData.is_active),
            type: parseInt(userData.type),
            balance: parseInt(userData.balance),
            password: userData.password
        }

        const action = await dispatch(updateUserDetails({ id: userData.id, data: data }))
        if (action.payload.status === 200) {
            handleClose()
        }
    }


    return (
        <div>
            <Dialog open={formOpenOrClose.open && formOpenOrClose.type === 'user'} onClose={handleClose}>
                {
                    updateMessage.message.length > 0 && <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert sx={{ display: 'flex', justifyContent: 'center' }} severity={updateMessage.loading === 'pending' ? 'info' : 'error'}>{updateMessage.message}</Alert>
                    </Stack>
                }
                {
                    updatePlanMessage.message.length > 0 && <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert sx={{ display: 'flex', justifyContent: 'center' }} severity={updatePlanMessage.loading === 'pending' ? 'info' : 'error'}>{updatePlanMessage.message}</Alert>
                    </Stack>
                }
                {
                    updateUserMessage.message.length > 0 && <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert sx={{ display: 'flex', justifyContent: 'center' }} severity={updateUserMessage.loading === 'pending' ? 'info' : 'error'}>{updateUserMessage.message}</Alert>
                    </Stack>
                }
                <DialogTitle>Renew Plan</DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDayjs} size='small'>
                        <DemoContainer
                            size='small'
                            components={[
                                'DatePicker'
                            ]}
                        >
                            <DatePicker disabled format='DD/MM/YYYY' value={dayjs(userPlanData.access_from)} slotProps={{ textField: { size: 'small' } }} label='From' />
                            <DatePicker format='DD/MM/YYYY' onChange={onPlanChange} slotProps={{ textField: { size: 'small' } }} disablePast value={dayjs(userPlanData.access_to)} label='To' />
                        </DemoContainer>
                    </LocalizationProvider>
                    <Button className='user_btn' size='small' variant='contained' sx={{mt:1, fontSize: "13px"}}>{handleCalculateDays(userPlanData.access_to) === 0 ? <span>Plan expired, Please renew</span> : <span>{handleCalculateDays(userPlanData.access_to)}&nbsp;Days remaining</span>}</Button>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        dispatch(userPlanUpdate({ id: userData.id, data: userPlanData }))
                    }}>Renew</Button>
                    <Button color='secondary' onClick={handleClose}>Cancel</Button>
                </DialogActions>
                <DialogTitle sx={{ pt: 0 }}>Update user</DialogTitle>
                <DialogContent>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '46%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            autoFocus
                            margin="dense"
                            name='name'
                            label="Name"
                            type="text"
                            value={userData.name}
                            size='small'
                            onChange={onChange}
                            autoComplete='off'
                            placeholder='Enter user name'
                            error={userError.field === 'name' && userError.type}
                            helperText={userError.field === 'name' && userError.message}
                        />
                        <TextField
                            margin="dense"
                            name='mobile'
                            label="Mobile"
                            type="text"
                            disabled
                            value={userData.mobile}
                            size='small'
                            autoComplete='off'
                            placeholder='Enter user mobile'
                        />
                        <TextField
                            error={userError.field === 'address' && userError.type}
                            helperText={userError.field === 'address' && userError.message}
                            margin="dense"
                            name='address'
                            label="Address"
                            type="text"
                            value={userData.address}
                            fullWidth
                            size='small'
                            onChange={onChange}
                            autoComplete='off'
                            placeholder='Enter user address'
                        />
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Active</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={userData.is_active}
                                label="Active"
                                name='is_active'
                                onChange={onChange}
                                size='small'
                            >
                                <MenuItem value={1}>Active</MenuItem>
                                <MenuItem value={0}>In-Active</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="outlined-adornment-amount">Balance</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-amount"
                                startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                                label="Amount"
                                size='small'
                                type='number'
                                name='balance'
                                value={userData.balance}
                                onChange={onChange}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={userData.type}
                                label="Active"
                                name='type'
                                onChange={onChange}
                                size='small'
                            >
                                <MenuItem value={0}>User</MenuItem>
                                <MenuItem value={1}>Admin</MenuItem>
                                <MenuItem value={2}>Sub-Admin</MenuItem>
                            </Select>
                        </FormControl>
                        {
                            parseInt(userData.type) !== 0 && <TextField
                                margin="dense"
                                name='password'
                                label="Password"
                                type="text"
                                value={userData.password}
                                fullWidth
                                size='small'
                                onChange={onChange}
                                autoComplete='off'
                                placeholder='Enter user password'
                                error={userError.field === 'password' && userError.type}
                                helperText={userError.field === 'password' && userError.message}
                            />
                        }
                    </Box>
                </DialogContent>
                <DialogActions>
                    {/* <Button color='error' onClick={handleClose}>Delete</Button> */}
                    <Button onClick={updateUserData}>Update</Button>
                    <Button color='secondary' onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


export default React.memo(FormDialog)