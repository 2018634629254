import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { setAlertOpenOrClose } from '../../features/finance/financeSlice';

export default function AlertDialog({ alertResponse }) {

    const { alertOpenOrClose } = useSelector(state => state.finance)
    const dispatch = useDispatch()

    const handleClose = ({ value }) => {
        alertResponse(value);
        dispatch(setAlertOpenOrClose(false))
    };

    return (
        <div>
            <Dialog
                open={alertOpenOrClose}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {" Are you sure want to delete?"}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => handleClose({ value: false })}>No</Button>
                    <Button color='error' onClick={() => handleClose({ value: true })} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}