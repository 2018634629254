import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { setFormOpenOrClose } from '../../features/common/commonSlice';
import { Alert, Box, DialogContentText, FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material';
import './finance.scss'
import { createFinance, deleteFinance, setAlertOpenOrClose, setUpdateMessage, updateFinance } from '../../features/finance/financeSlice';
import AlertDialog from './Alert';
import isEmpty from 'validator/lib/isEmpty';
import isMobilePhone from 'validator/lib/isMobilePhone';


const initialState = {
    name: '',
    mobile1: null,
    mobile2: null,
    mobile3: null
}

export default function FormDialog() {
    const [financeData, setFinanceData] = React.useState(initialState)
    const { data, updateMessage } = useSelector(state => state.finance)
    const { headOffice } = useSelector(state => state.office)
    const [errorType, setErrorType] = React.useState({
        type: '',
        message: ''
    })

    const alertResponse = async (value) => {
        if (value) {
            const action = await dispatch(deleteFinance({ finance_id: data[0].id }))
            if (action.payload.status === 200) {
                dispatch(setFormOpenOrClose({ type: 'finance', open: false }))
            }
        } else {
            console.log('not');
        }
    }

    React.useEffect(() => {
        setErrorType({
            type: '',
            message: ''
        })
        dispatch(setUpdateMessage({
            message: '',
            loading: ''
        }))
        if (data.length > 0) {
            setFinanceData(data[0])
        } else {
            setFinanceData(initialState)
        }

        return () => {
            return
        }
    }, [data])

    const formOpenOrClose = useSelector(state => state.common.formOpenOrClose)
    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(setFormOpenOrClose({ type: 'finance', open: false }))
    };



    const handleDelete = () => {
        dispatch(setAlertOpenOrClose(true))
    }



    const onChange = (event) => {
        let { value, name } = event.target
        // if (name === 'name') {
        //     value = String(value).toUpperCase()
        // }
        setFinanceData({
            ...financeData,
            [name]: String(value).toUpperCase()
        })
    }

    const handleSubmit = async ({ type }) => {

        // let mobile1 = false, mobile2 = false, mobile3 = false

        if (isEmpty(financeData.name)) {
            return setErrorType({
                name: 'name',
                message: 'Finance name is required',
            })
        }

        // if ((financeData.mobile1 === null)) {
        //     mobile1 = true
        // } else if (isEmpty(financeData.mobile1)) {
        //     mobile1 = true
        // } else if (isMobilePhone(String(financeData.mobile1))) {
        //     mobile1 = true
        // } else {
        //     return setErrorType({
        //         name: 'mobile1',
        //         message: 'Invalid mobile number',
        //     })
        // }

        // if ((financeData.mobile2 === null)) {
        //     mobile2 = true
        // } else if (isEmpty(financeData.mobile2)) {
        //     mobile2 = true
        // } else if (isMobilePhone(String(financeData.mobile2))) {
        //     mobile2 = true
        // } else {
        //     return setErrorType({
        //         name: 'mobile2',
        //         message: 'Invalid mobile number',
        //     })
        // }

        // if ((financeData.mobile3 === null)) {
        //     mobile3 = true
        // } else if (isEmpty(financeData.mobile3)) {
        //     mobile3 = true
        // } else if (isMobilePhone(String(financeData.mobile3))) {
        //     mobile3 = true
        // } else {
        //     return setErrorType({
        //         name: 'mobile3',
        //         message: 'Invalid mobile number'
        //     })
        // }
        setErrorType({
            name: '',
            message: '',
        })


        switch (type) {
            case 'update':
                const updateAction = await dispatch(updateFinance({ finance_id: data[0].id, data: { name: financeData.name, mobile1: financeData.mobile1, mobile2: financeData.mobile2, mobile3: financeData.mobile3 } }))
                if (updateAction.payload.status === 200) {
                    dispatch(setFormOpenOrClose({ type: 'finance', open: false }))
                }
                break;
            case 'create':
                const createAction = await dispatch(createFinance({ data: { ...financeData, finance_id: headOffice.id } }))
                if (createAction.payload.status === 200) {
                    dispatch(setFormOpenOrClose({ type: 'finance', open: false }))
                }
                break;

            default:
                break;
        }
    }

    return (
        <>
            <div>
                <Dialog open={formOpenOrClose.open && formOpenOrClose.type === 'finance'} onClose={handleClose}>
                    {
                        updateMessage.message.length > 0 && <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert sx={{ display: 'flex', justifyContent: 'center' }} severity={updateMessage.loading === 'pending' ? 'info' : 'error'}>{updateMessage.message}</Alert>
                        </Stack>
                    }
                    <DialogTitle sx={{ pb: 0 }}>{data.length > 0 ? 'Update Finance' : 'Create New Finance'}</DialogTitle>
                    <Typography sx={{ textAlign: 'center', m: 0, mb: 1, fontSize: '20px', fontWeight: 600, color: 'gray' }}>{headOffice.name}</Typography>
                    <DialogContent sx={{ pt: 0 }}>
                        <Box
                            sx={{
                                '& > :not(style)': { m: 1, width: '46%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                helperText={errorType.name === 'name' && errorType.message} error={errorType.name === 'name'}
                                autoFocus
                                margin="dense"
                                name='name'
                                label="Finance Name"
                                type="text"
                                value={String(financeData.name).toUpperCase()}
                                size='small'
                                onChange={onChange}
                                autoComplete='off'
                                placeholder='Enter finance name'
                            />
                            <TextField
                                // helperText={errorType.name === 'mobile1' && errorType.message} error={errorType.name === 'mobile1'}
                                margin="dense"
                                name='mobile1'
                                label="Contact First"
                                type="text"
                                value={financeData.mobile1}
                                onChange={onChange}
                                size='small'
                                autoComplete='off'
                                placeholder='Enter contact first'
                            />
                            <TextField
                                // helperText={errorType.name === 'mobile2' && errorType.message} error={errorType.name === 'mobile2'}
                                margin="dense"
                                name='mobile2'
                                label="Contact Second"
                                type="text"
                                value={financeData.mobile2}
                                fullWidth
                                size='small'
                                onChange={onChange}
                                autoComplete='off'
                                placeholder='Enter contact second'
                            />
                            <TextField
                                // helperText={errorType.name === 'mobile3' && errorType.message} error={errorType.name === 'mobile3'}
                                margin="dense"
                                name='mobile3'
                                label="Contact Third"
                                type="text"
                                value={financeData.mobile3}
                                fullWidth
                                size='small'
                                onChange={onChange}
                                autoComplete='off'
                                placeholder='Enter contact third'
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        {data.length > 0 && <Button color='error' onClick={handleDelete}>Delete</Button>}
                        {data.length > 0 ? <Button onClick={(event) => {
                            event.preventDefault()
                            handleSubmit({ type: 'update' })
                        }}>Update</Button> : <Button onClick={(event) => {
                            event.preventDefault()
                            handleSubmit({ type: 'create' })
                        }}>Create</Button>}
                        <Button color='secondary' onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </div>
            <AlertDialog alertResponse={alertResponse} />
        </>
    );
}