import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { allAdmin, setSearchValue, setUserAccessData } from '../../features/admin/adminSlice'

const Search = () => {
    const { page_number, searchValue } = useSelector(state => state.admin)
    const dispatch = useDispatch()
    const dataSearchFunction = async (event) => {
        event.preventDefault()
        dispatch(setUserAccessData([]))
        dispatch(allAdmin({ data: searchValue, page_number: 1 }))
    }

    useEffect(() => {
        let fetching = false
        dispatch(allAdmin({
            data: {
                type: searchValue.type,
                search_value: '',
                confirm: searchValue.confirm,
                fetchType: searchValue.fetchType
            }, page_number: 1
        }))

        dispatch(setUserAccessData([]))
        return () => {
            fetching = true
        }
    }, [searchValue.confirm, searchValue.type])

    const inputSearchValue = (event) => {
        dispatch(setSearchValue({
            type: searchValue.type,
            search_value: event.target.value,
            confirm: searchValue.confirm,
            fetchType: searchValue.fetchType
        }))
        if (event.target.value === '') {
            dispatch(allAdmin({
                data: {
                    type: searchValue.type,
                    search_value: event.target.value,
                    confirm: searchValue.confirm,
                    fetchType: searchValue.fetchType
                }, page_number: 1
            }))
        }
    }

    return (
        <div className='search_container'>
            <form onSubmit={dataSearchFunction} className='form_container'>
                <input className='form_input' placeholder={`Search ${searchValue.confirm === 1 ? 'active' : searchValue.confirm === 0 ? 'in-active': 'all'} ${searchValue.type === 1 ? 'admin' : 'sub-admin'}`} type="text" value={searchValue.search_value} onChange={inputSearchValue} />
                <input className='form_input' type="submit" value='Search' />
            </form>
            <FormControl sx={{ width: '15%', mr: 1 }}>
                <InputLabel id="demo-simple-select-label">Filter By</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={searchValue.type}
                    label="Search By"
                    size='small'
                    onChange={(event) => {
                        dispatch(setSearchValue({ type: parseInt(event.target.value), search_value: '', confirm: searchValue.confirm, fetchType: searchValue.fetchType }))
                    }}
                >
                    <MenuItem value={1}>Admin</MenuItem>
                    <MenuItem value={2}>Sub-Admin</MenuItem>
                </Select>
            </FormControl>
            <FormControl sx={{ width: '15%' }}>
                <InputLabel id="demo-simple-select-label2">Filter By</InputLabel>
                <Select
                    labelId="demo-simple-select-label2"
                    id="demo-simple-select"
                    value={searchValue.confirm}
                    label="Search By"
                    size='small'
                    onChange={(event) => {
                        dispatch(setSearchValue({ type: searchValue.type, search_value: '', confirm: parseInt(event.target.value), fetchType: parseInt(event.target.value) === 2 ? 1 : 0 }))
                    }}
                >
                    <MenuItem value={2}>All</MenuItem>
                    <MenuItem value={1}>Active</MenuItem>
                    <MenuItem value={0}>In-Active</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}

export default Search