import { Box, LinearProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React, { memo, useCallback, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux'
import { setFormOpenOrClose } from '../../features/common/commonSlice';
import './confirmRequest.scss'
import { setVehicleDetails, vehicleConfirmRequest } from '../../features/dashboard/dashboardSlice';
import DetailsDialog from './DetailsDialog';




const createData = (user_name, rc_number, chassis_number, engine_number, action) => {
    return { user_name, rc_number, chassis_number, engine_number, action };
}



const columns = [
    { id: 'user_name', label: 'User Name', minWidth: 200 },
    { id: 'rc_number', label: 'RC Number', minWidth: 200 },
    { id: 'chassis_number', label: 'Chassis Number', minWidth: 30, align: 'center', },
    { id: 'engine_number', label: 'Engine Number', minWidth: 100, align: 'center' },
];



const VehicleConfirmRequest = () => {
    const { totalConfirmVehicleByUser, vehicleDetails, message, totalSearchData, searchValue, page_number } = useSelector(state => state.dashboard)
    const dispatch = useDispatch()


    const rows = useMemo(() => {
        return totalConfirmVehicleByUser.map((vehicle) => {
            return createData(vehicle.name, vehicle.rc_number, vehicle.chassis_number, vehicle.engine_number, vehicle.vehicle_id)
        });
    }, [totalConfirmVehicleByUser])


    return (
        <>
            {
                message.length !== 0 ? <Typography sx={{ mt: 1, p: 2, textAlign: 'center' }} component={Paper}>
                    {message}
                </Typography> : <>
                    <InfiniteScroll
                        dataLength={totalConfirmVehicleByUser.length}
                        className='infinite_scroll_vehicle'
                        next={() => {
                            dispatch(vehicleConfirmRequest({ searchValue: searchValue, page_number: page_number }))
                        }}
                        height='500px'
                        hasMore={totalConfirmVehicleByUser.length < totalSearchData}
                        loader={
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                        }
                    >
                        <table className='table_container'>
                            <thead sx={{ height: '10px' }} className='table_head_wrapper'>
                                <tr className='table_content_wrapper'>
                                    {columns.map((column) => (
                                        <th
                                            sx={{ border: '.2px solid lightGray', minWidth: column.minWidth }}
                                            className='table_row'
                                            size='small'
                                            key={column.id}
                                            align={column.align}
                                        >
                                            {column.label}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className='table_body_wrapper'>
                                {rows
                                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <tr className='table_body_content_wrapper' key={row.id} onClick={(event) => {
                                                event.stopPropagation()
                                                dispatch(setFormOpenOrClose({ type: 'details', open: true }))
                                                dispatch(setVehicleDetails(row.action))
                                            }} >
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    if (column.id === 'rc_number') {
                                                        return (
                                                            <td key={column.id} className='table_body_content'>
                                                                {column.format && typeof value === 'number'
                                                                    ? column.format(value)
                                                                    : value !== null ? String(value).replace(/([A-Z]{1,2})(\d{1,2})([A-Z]{1,2})(\d{1,4})/, "$1-$2-$3-$4") : value}
                                                            </td>
                                                        );
                                                    }
                                                    return (
                                                        <td key={column.id} className='table_body_content'>
                                                            {column.format && typeof value === 'number'
                                                                ? column.format(value)
                                                                : value}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table >
                    </InfiniteScroll >
                    {vehicleDetails.length > 0 && <DetailsDialog />}
                </>
            }
        </>
    )
}

export default memo(VehicleConfirmRequest)