import React, { memo, useState } from 'react'
import { CellMeasurer } from 'react-virtualized';
import { cache } from '../../features/excel/excelSlice';

const FileCellEditor = ({ rowIndex, rowData, dataKey, cellData, columnIndex, onCellChange, parent }) => {

    const [value, setValue] = useState(cellData);

    const handleChange = (event) => {
        setValue(event.target.value);
    };


    const onBlur = () => {
        onCellChange(rowData, rowIndex, dataKey, value);
    }


    const onKeyUp = (event) => {
        if (event.key === 'Enter')
            onCellChange(rowData, rowIndex, dataKey, value);

    }

    return (
        <CellMeasurer
            cache={cache}
            columnIndex={columnIndex}
            key={dataKey}
            parent={parent}
            rowIndex={rowIndex}
        >
            <input type="text" className='input' style={{ cursor: 'cell' }} onKeyUp={onKeyUp} value={value} onBlur={onBlur} onChange={handleChange} />
        </CellMeasurer>
    );
};


export default memo(FileCellEditor)