import { Box, LinearProgress, Paper, Typography } from '@mui/material';
import React, { memo, useMemo } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux'
import './details.scss'
import { allDetail } from '../../features/details/detailsSlice';




const createData = (user_name, rc_number, chassis_number, mek_and_model, latitude, longitude, location, search_time) => {
    const date = timeConverter(search_time)
    return { user_name, rc_number, chassis_number, mek_and_model, latitude, longitude, location, date };
}


const columns = [
    { id: 'user_name', label: 'User Name', minWidth: 200 },
    { id: 'rc_number', label: 'RC Number', minWidth: 100, align: 'center' },
    { id: 'chassis_number', label: 'Chassis Number', minWidth: 100, align: 'center' },
    { id: 'mek_and_model', label: 'Mek & Model', minWidth: 100, align: 'center' },
    { id: 'latitude', label: 'Latitude', minWidth: 100, align: 'center' },
    { id: 'longitude', label: 'Longitude', minWidth: 100, align: 'center' },
    { id: 'location', label: 'Location', minWidth: 100, align: 'center' },
    { id: 'date', label: 'Date & Time', minWidth: 100, align: 'center' },
];


const timeConverter = (value) => {
    // Set the IST time
    let istDate = new Date(value); // Current date and time in IST
    return (istDate.toLocaleString('en-IN', { hour12: true }));

}


const DetailsTable = () => {
    const { totalDetails, message, totalSearchData, searchValue, page_number } = useSelector(state => state.details)
    const dispatch = useDispatch()

    const rows = useMemo(() => {
        return totalDetails.map((details) => {
            return createData(details.user_name, details.rc_number, details.chassis_number, details.mek_and_model, details.latitude, details.longitude, details.location, details.search_time)
        });
    }, [totalDetails])


    return (
        <>
            {
                message.length !== 0 ? <Typography sx={{ mt: 1, p: 2, textAlign: 'center' }} component={Paper}>
                    {message}
                </Typography> :
                    <InfiniteScroll
                        dataLength={totalDetails.length}
                        className='infinite_scroll_details'
                        next={() => {
                            dispatch(allDetail({ searchValue: searchValue, page_number: page_number }))
                        }}
                        height='calc(100vh - 120px)'
                        hasMore={totalDetails.length < totalSearchData}
                        loader={
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                        }
                    >
                        <table className='table_container'>
                            <thead sx={{ height: '10px' }} className='table_head_wrapper'>
                                <tr className='table_content_wrapper'>
                                    {columns.map((column) => (
                                        <th
                                            sx={{ border: '.2px solid lightGray', minWidth: column.minWidth }}
                                            className='table_row'
                                            size='small'
                                            key={column.id}
                                            align={column.align}
                                        >
                                            {column.label}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className='table_body_wrapper'>
                                {rows
                                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <tr className='table_body_content_wrapper' key={row.id} onClick={(event) => {
                                                event.stopPropagation()
                                            }} >
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <td key={column.id} className='table_body_content'>
                                                            {column.format && typeof value === 'number'
                                                                ? column.format(value)
                                                                : value}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table >
                    </InfiniteScroll >
            }
        </>
    )
}

export default memo(DetailsTable)