import { Box, LinearProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React, { memo, useCallback, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux'
import { allUser } from '../../features/user/userSlice';
import { dateString, setFormOpenOrClose, timeConverters } from '../../features/common/commonSlice';
import './finance.scss'
import FormDialog from './FinanceForm';
import { allFinance, deleteFinanceRecords, setData } from '../../features/finance/financeSlice';




const createData = (name, record, upload, action) => {
    const upload_date = timeConverters(upload)
    return { name, record, upload_date, action };
}



const columns = [
    { id: 'name', label: 'Finance Name', minWidth: 200, align: 'start',fontWeight: 500 },
    { id: 'record', label: 'Records', minWidth: 30, align: 'center', },
    { id: 'upload_date', label: 'Upload', minWidth: 30, align: 'center', },
    { id: 'action', label: 'Action', minWidth: 100, align: 'center' },
];



const FinanceTable = () => {
    const { totalFinance, message, totalSearchData, searchValue, financeId, page_number, data } = useSelector(state => state.finance)
    const { headOffice } = useSelector(state => state.office)
    const { isAdmin } = useSelector(state => state.auth)
    const dispatch = useDispatch()




    const financeDetails = useCallback((value) => {
        const values = totalFinance.filter(finance => finance.id === value)
        dispatch(setData(values))
        dispatch(setFormOpenOrClose({ type: 'finance', open: true }))
        return
    })


    const rows = useMemo(() => {
        return totalFinance.map((finance) => {
            return createData(finance.name, finance.total_record, finance.upload_date, finance.id)
        });
    }, [totalFinance])


    return (
        message.length !== 0 ? <Typography sx={{ mt: 1, p: 2, textAlign: 'center' }} component={Paper}>
            {message}
        </Typography> : <>
            <Typography color='white' sx={{ mt: 0, p: 1, textAlign: 'center', bgcolor: 'orange' }} component={Paper}>
                {headOffice.name}
            </Typography>
            <InfiniteScroll
                dataLength={totalFinance.length}
                className='infinite_scroll_finance'
                next={() => {
                    dispatch(allFinance({ searchValue: searchValue, page_number: page_number, finance_id: headOffice.id }))
                }}
                height='calc(100vh - 160px)'
                hasMore={totalFinance.length < totalSearchData}
                loader={
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                }
            >
                <table className='table_container'>
                    <thead sx={{ height: '10px' }} className='table_head_wrapper'>
                        <tr className='table_content_wrapper'>
                            {columns.map((column) => {
                                if (column.id === 'action') {
                                    if (isAdmin) {
                                        return (
                                            <th
                                                sx={{ border: '.2px solid lightGray', minWidth: column.minWidth }}
                                                className='table_row'
                                                size='small'
                                                key={column.id}
                                                align={column.align}
                                            >
                                                {column.label}
                                            </th>
                                        )
                                    } else {
                                        return
                                    }
                                }
                                return (
                                    <th
                                        sx={{ border: '.2px solid lightGray', minWidth: column.minWidth }}
                                        className='table_row'
                                        size='small'
                                        key={column.id}
                                        style={{ textAlign: column.align }}
                                    >
                                        {column.label}
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody className='table_body_wrapper'>
                        {rows
                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <tr className='table_body_content_wrapper' key={row.id} onClick={(event) => {
                                        event.stopPropagation()
                                        if (isAdmin)
                                            financeDetails(row.action)
                                    }} >
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            if (column.id === 'action') {
                                                if (isAdmin) {
                                                    return (
                                                        <td key={column.id} className='table_body_content'>
                                                            <button className='user_btn' size='small' sx={{ marginLeft: '5px' }} onClick={(event) => {
                                                                event.stopPropagation()
                                                                dispatch(deleteFinanceRecords({ finance_id: row.action }))
                                                            }}>Delete</button>
                                                        </td>
                                                    );
                                                } else {
                                                    return
                                                }
                                            }
                                            return (
                                                <td key={column.id} style={{ textAlign: column.align, fontWeight: column.fontWeight }} className='table_body_content'>
                                                    {column.format && typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                    </tbody>
                </table >
            </InfiniteScroll >
        </>
    )
}

export default memo(FinanceTable)