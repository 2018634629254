import React from 'react'
import ExcelFileReader from '../../components/file/FileReader'
import FileDataRender from '../../components/file/FileDataRender'

const FileUpload = () => {

    return (
        <div style={{marginTop: '10px'}}>
            <FileDataRender />
        </div>
    )
}

export default FileUpload