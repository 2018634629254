import { createSlice } from '@reduxjs/toolkit'
import isEmail from 'validator/lib/isEmail'
import isEmpty from 'validator/lib/isEmpty'
import isMobilePhone from 'validator/lib/isMobilePhone'
import isStrongPassword from 'validator/lib/isStrongPassword'


export const EmptyValidation = (value) => {
    if (isEmpty(value)) {
        return true
    } else {
        return false
    }
}


export const MobilePhoneValidation = (value) => {
    if (isMobilePhone(value, 'en-IN')) {
        return true
    } else {
        return false
    }
}


export const passwordValidation = (value) => {
    if (isStrongPassword(value)) {
        return true
    } else {
        return false
    }
}

export const dateString = (value) => {
    // Create a Date object from the input date string
    const date = new Date(value);
    // Extract day, month, and year from the Date object
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());
    // Form the resulting date string in the desired format
    const result = `${day}-${month}-${year}`;
    return result
}


export const timeConverters = (value) => {
    const date = new Date(value);
    const options = { timeZone: 'Asia/Kolkata', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    const formattedDate = date.toLocaleString('en-IN', options);
    // console.log(formattedDate); // "10 May 2023, 7:01:10 PM"
    return formattedDate
}



export const serverDateString = (value) => {
    // Create a Date object from the input date string
    const date = new Date(value);
    // Extract day, month, and year from the Date object
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());
    // Form the resulting date string in the desired format
    const result = `${year}-${month}-${day}`;
    return result
}


const initialState = {
    formOpenOrClose: {
        type: '',
        open: false
    }
}

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setFormOpenOrClose: (state, action) => {
            state.formOpenOrClose = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { setFormOpenOrClose } = commonSlice.actions

export default commonSlice.reducer