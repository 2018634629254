import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { allDetail, setSearchValue } from '../../features/details/detailsSlice'
import { serverDateString } from '../../features/common/commonSlice'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';

const Search = () => {
    const { page_number, searchValue } = useSelector(state => state.details)
    const dispatch = useDispatch()
    const dataSearchFunction = async (event) => {
        event.preventDefault()
        dispatch(allDetail({ searchValue, page_number: 1 }))
    }

    useEffect(() => {
        let fetching = false
        dispatch(setSearchValue({
            date: serverDateString(dayjs(Date.now())),
            search_value: ''
        }))
        dispatch(allDetail({
            searchValue: {
                date: serverDateString(dayjs(Date.now())),
                search_value: ''
            }, page_number: 1
        }))
        return () => {
            fetching = true
        }
    }, [])

    const inputSearchValue = (event) => {
        dispatch(setSearchValue({
            date: searchValue.date,
            search_value: event.target.value
        }))
        if (event.target.value === '') {
            dispatch(allDetail({
                searchValue: {
                    date: searchValue.date,
                    search_value: event.target.value
                }, page_number: 1
            }))
        }
    }

    return (
        <div className='search_container'>
            <form onSubmit={dataSearchFunction} className='form_container'>
                <input className='form_input' placeholder='Search by Engine Number, Chassis Number, User Name' type="text" value={searchValue.search_value} onChange={inputSearchValue} />
                <input className='form_input' type="submit" value='Search' />
            </form>
            <LocalizationProvider dateAdapter={AdapterDayjs} size='small'>
                <DatePicker format='DD/MM/YYYY' disableFuture value={dayjs(searchValue.date)} onChange={(event) => {
                    dispatch(setSearchValue({
                        date: serverDateString(event.$d),
                        search_value: ''
                    }))
                    dispatch(allDetail({
                        searchValue: {
                            date: serverDateString(event.$d),
                            search_value: ''
                        }, page_number: 1
                    }))
                }} slotProps={{ textField: { size: 'small' } }} />
            </LocalizationProvider>
        </div>
    )
}

export default Search