import { Box, LinearProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React, { memo, useCallback, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux'
import { allUser, setUserAccessData } from '../../features/user/userSlice';
import { setFormOpenOrClose } from '../../features/common/commonSlice';
import { allAdmin } from '../../features/admin/adminSlice';




const createData = (name, mobile, address, action, kyc, image, id) => {
    return { name, mobile, address, action, kyc, image, id };
}

let columns;


const AdministratorTable = () => {
    const { totalAdmin, message, totalSearchData, searchValue, page_number } = useSelector(state => state.admin)
    const [data, setData] = useState([])
    const dispatch = useDispatch()



    if (searchValue.type === 2) {
        columns = [
            { id: 'name', label: 'Name', minWidth: 200, align: 'start',  },
            { id: 'mobile', label: 'Mobile', minWidth: 30, align: 'center', },
            { id: 'address', label: 'Address', minWidth: 170, align: 'center', },
            { id: 'action', label: 'Action', minWidth: 100, align: 'center' },
        ];
    } else {
        columns = [
            { id: 'name', label: 'Name', minWidth: 200, align: 'start', },
            { id: 'mobile', label: 'Mobile', minWidth: 30, align: 'center', },
            { id: 'address', label: 'Address', minWidth: 170, align: 'center', },
        ];
    }

    const userDetails = useCallback((value) => {
        const values = totalAdmin.filter(user => user.id === value)
        setData(values)
        dispatch(setFormOpenOrClose({ type: 'user', open: true }))
        return
    })


    const rows = useMemo(() => {
        return totalAdmin.map((user) => {
            // const branch = filterBranch({ branch: totalBranch, finance_id: finance.id, type: 'createData' })
            return createData(user.name, user.mobile, user.address, user.id, user.kyc_image, user.image, user.id)
        });
    }, [totalAdmin])


    return (
        message.length !== 0 ? <Typography sx={{ mt: 1, p: 2, textAlign: 'center' }} component={Paper}>
            {message}
        </Typography> : <>
            <InfiniteScroll
                dataLength={totalAdmin.length}
                className='infinite_scroll_user'
                next={() => {
                    dispatch(allAdmin({ data: searchValue, page_number: page_number }))
                }}
                height='calc(100vh - 120px)'
                hasMore={totalAdmin.length < totalSearchData}
                loader={
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                }
            >
                <table className='table_container'>
                    <thead sx={{ height: '10px' }} className='table_head_wrapper'>
                        <tr className='table_content_wrapper'>
                            {columns.map((column) => (
                                <th
                                    sx={{ border: '.2px solid lightGray', minWidth: column.minWidth }}
                                    className='table_row'
                                    size='small'
                                    key={column.id}
                                    style={{textAlign: column.align}}
                                >
                                    {column.label}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className='table_body_wrapper'>
                        {rows
                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <tr className='table_body_content_wrapper' key={row.id} onClick={(event) => {
                                        event.stopPropagation()
                                        // userDetails(row.id)
                                    }} >
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            if (searchValue.type === 2)
                                                if (column.id === 'action') {
                                                    return (
                                                        <td key={column.id} className='table_body_content'>
                                                            <button className='user_btn' color='success' size='small' sx={{ marginLeft: '5px' }} onClick={(event) => {
                                                                event.stopPropagation()
                                                                // dispatch(setUserAccessData(row.action))
                                                            }}>Access</button>
                                                        </td>
                                                    );
                                                }
                                            return (
                                                <td key={column.id} style={{textAlign: column.align}} className='table_body_content'>
                                                    {column.format && typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                    </tbody>
                </table >
            </InfiniteScroll >
        </>
    )
}

export default memo(AdministratorTable)