import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { setFormOpenOrClose } from '../../features/common/commonSlice';
import { Alert, Box, FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack } from '@mui/material';
import { createOffice, deleteOffice, setAlertOpenOrClose, setData, setUpdateMessage, updateOffice } from '../../features/office/officeSlice';
import AlertDialog from './Alert';
import isEmpty from 'validator/lib/isEmpty';


const initialState = {
  name: ''
}

export default function FormDialog() {
  const [userData, setUserData] = React.useState(initialState)
  const { data, updateMessage } = useSelector(state => state.office)
  const formOpenOrClose = useSelector(state => state.common.formOpenOrClose)
  const [errorType, setErrorType] = React.useState({
    type: '',
    message: ''
  })
  const dispatch = useDispatch()



  React.useEffect(() => {
    setErrorType({
      type: '',
      message: ''
    })
    dispatch(setUpdateMessage({
      message: '',
      loading: ''
    }))
    if (data.length > 0) {
      setUserData(data[0])
    } else {
      setUserData(initialState)
    }

    return () => {
      return
    }
  }, [data])


  const handleClose = () => {
    dispatch(setFormOpenOrClose({ type: 'office', open: false }))
  };

  const alertResponse = async (value) => {
    if (value) {
      const action = await dispatch(deleteOffice({ office_id: data[0].id }))
      if (action.payload.status === 200) {
        dispatch(setFormOpenOrClose({ type: 'office', open: false }))
        dispatch(setData([]))
      }
    } else {
      console.log('not');
    }
  }


  const handleDelete = () => {
    dispatch(setAlertOpenOrClose(true))
  }


  const onChange = (event) => {
    const { value, name } = event.target
    setUserData({
      ...userData,
      [name]: String(value).toUpperCase()
    })
  }



  const handleSubmit = async ({ type }) => {

    if (isEmpty(userData.name)) {
      return setErrorType({
        name: 'name',
        message: 'Head Office name is required',
      })
    }

    switch (type) {
      case 'update':
        const updateAction = await dispatch(updateOffice({ office_id: data[0].id, data: { name: userData.name } }))
        if (updateAction.payload.status === 200) {
          dispatch(setFormOpenOrClose({ type: 'office', open: false }))
        }
        break;
      case 'create':
        const createAction = await dispatch(createOffice({ data: userData }))
        if (createAction.payload.status === 200) {
          dispatch(setFormOpenOrClose({ type: 'office', open: false }))
        }
        break;

      default:
        break;
    }
  }

  return (
    <>
      <div>
        <Dialog open={formOpenOrClose.open && formOpenOrClose.type === 'office'} onClose={handleClose}>
          {
            updateMessage.message.length > 0 && <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert sx={{ display: 'flex', justifyContent: 'center' }} severity={updateMessage.loading === 'pending' ? 'info' : 'error'}>{updateMessage.message}</Alert>
            </Stack>
          }
          <DialogTitle sx={{ pb: 0 }}>{data.length > 0 ? 'Update Head Office' : 'Create New Head Office'}</DialogTitle>
          <DialogContent sx={{ width: '500px' }}>
            <Box
              sx={{
                '& > :not(style)': { m: 1, width: '100%' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                helperText={errorType.name === 'name' && errorType.message} error={errorType.name === 'name'}
                autoFocus
                fullWidth
                margin="dense"
                name='name'
                label="Head Office Name"
                type="text"
                value={userData.name}
                size='small'
                onChange={onChange}
                autoComplete='off'
                placeholder='Enter Head Office name'
              />
            </Box>
          </DialogContent>
          <DialogActions>
            {data.length > 0 && <Button color='error' onClick={handleDelete}>Delete</Button>}
            {data.length > 0 ? <Button onClick={(event) => {
              event.preventDefault()
              handleSubmit({ type: 'update' })
            }}>Update</Button> : <Button onClick={(event) => {
              event.preventDefault()
              handleSubmit({ type: 'create' })
            }}>Create</Button>}
            <Button color='secondary' onClick={handleClose}>Cancel</Button>
          </DialogActions>

        </Dialog>
      </div >
      <AlertDialog alertResponse={alertResponse} />
    </>
  );
}