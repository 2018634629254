import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { dateString, setFormOpenOrClose } from '../../features/common/commonSlice';
import './confirmRequest.scss'
import { Alert, Box, ImageList, ImageListItem, Stack } from '@mui/material';
import { setVehicleDetailsSubmit, vehicleConfirmAction } from '../../features/dashboard/dashboardSlice';



function columns(valueKey, value) {
    return <tr key={valueKey} className='confirm_details_table_body_row_container'>
        <td className='confirm_details_table_body_content_container_left'>
            <span >{valueKey.toUpperCase().split("_").join(" ")}</span>
        </td>
        <td className='confirm_details_table_body_content_container_middle'>
            <span >:</span>
        </td>
        <td className='confirm_details_table_body_content_container_right'>
            <span>{value}</span>
        </td>
    </tr>
}





export default function DetailsDialog() {
    const { vehicleDetails, updateMessage } = useSelector(state => state.dashboard)
    const { formOpenOrClose } = useSelector(state => state.common)
    const dispatch = useDispatch()

    console.log({ vehicleDetails, formOpenOrClose });



    const handleClose = () => {
        dispatch(setFormOpenOrClose({ type: 'details', open: false }))
    };

    const handleSubmit = async (type) => {
        switch (type) {
            case 'accept':
                const updateAction = await dispatch(vehicleConfirmAction({ data: { confirm: 1, vehicle_id: vehicleDetails[0].vehicle_id }, id: vehicleDetails[0].confirm_id }))
                if (updateAction.payload.status === 200) {
                    dispatch(setVehicleDetailsSubmit())
                    dispatch(setFormOpenOrClose({ type: 'details', open: false }))
                }
                break;
            case 'reject':
                const createAction = await dispatch(vehicleConfirmAction({ data: { confirm: 0, vehicle_id: vehicleDetails[0].vehicle_id }, id: vehicleDetails[0].confirm_id }))
                if (createAction.payload.status === 200) {
                    dispatch(setVehicleDetailsSubmit())
                    dispatch(setFormOpenOrClose({ type: 'details', open: false }))
                }
                break;

            default:
                break;
        }
    }



    const ImageSection = (value) => {
        return <ImageListItem>
            <img
                src={`${value}?w=164&h=164&fit=crop&auto=format`}
                srcSet={`${value}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                alt="image"
                loading="lazy"
                onClick={() => {
                    const link = document.createElement('a');
                    link.href = value;
                    link.target = '_blank'
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }}
                style={{ cursor: 'pointer' }}
            />
        </ImageListItem>
    }

    return (
        <>
            <div>
                <Dialog open={formOpenOrClose.open && formOpenOrClose.type === 'details'} onClose={handleClose}>
                    {
                        updateMessage.message.length > 0 && <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert sx={{ display: 'flex', justifyContent: 'center' }} severity={updateMessage.loading === 'pending' ? 'info' : 'error'}>{updateMessage.message}</Alert>
                        </Stack>
                    }
                    <DialogTitle sx={{ pb: 0 }}>Vehicle Confirm Details</DialogTitle>
                    <DialogContent sx={{ pt: 0, width: '500px' }}>
                        <Box sx={{
                            padding: "25px"
                        }}>
                            <ImageList sx={{ width: "100%", height: "100%" }} cols={3} rowHeight={164}>
                                {vehicleDetails[0].first !== null && ImageSection(vehicleDetails[0].first)}
                                {vehicleDetails[0].second !== null && ImageSection(vehicleDetails[0].second)}
                                {vehicleDetails[0].third !== null && ImageSection(vehicleDetails[0].third)}
                                {vehicleDetails[0].forth !== null && ImageSection(vehicleDetails[0].forth)}
                                {vehicleDetails[0].fifth !== null && ImageSection(vehicleDetails[0].fifth)}
                            </ImageList>
                        </Box>

                        <table className='confirm_details_table_container'>
                            <tbody className='confirm_details_table_body_container'>
                                {columns("financer", vehicleDetails[0].financer)}
                                {columns("contract_no", vehicleDetails[0].contract_no)}
                                {columns("customer_name", vehicleDetails[0].customer_name)}
                                {columns("rc_number", vehicleDetails[0].rc_number !== null ? String(vehicleDetails[0].rc_number).replace(/([A-Z]{1,2})(\d{1,2})([A-Z]{1,2})(\d{1,4})/, "$1-$2-$3-$4") : vehicleDetails[0].rc_number)}
                                {columns("mek_and_model", vehicleDetails[0].mek_and_model)}
                                {columns("engine_number", vehicleDetails[0].engine_number)}
                                {columns("chassis_number", vehicleDetails[0].chassis_number)}
                                {columns("branch", vehicleDetails[0].branch)}
                                {columns("region", vehicleDetails[0].region)}
                                {columns("area", vehicleDetails[0].area)}
                                {columns("bkt", vehicleDetails[0].bkt)}
                                {columns("od", vehicleDetails[0].od)}
                                {columns("poss", vehicleDetails[0].poss)}
                                {columns("gv", vehicleDetails[0].gv)}
                                {columns("ses9", vehicleDetails[0].ses9)}
                                {columns("ses17", vehicleDetails[0].ses17)}
                                {columns("tbr", vehicleDetails[0].tbr)}
                                {columns("Ex name", vehicleDetails[0].ex_name)}
                                {columns("level1", vehicleDetails[0].level1)}
                                {columns("level1con", vehicleDetails[0].level1con)}
                                {columns("level2", vehicleDetails[0].level2)}
                                {columns("level2con", vehicleDetails[0].level2con)}
                                {columns("level3", vehicleDetails[0].level3)}
                                {columns("level3con", vehicleDetails[0].level3con)}
                                {columns("level4", vehicleDetails[0].level4)}
                                {columns("level4con", vehicleDetails[0].level4con)}
                                {columns("uplaod", dateString(vehicleDetails[0].upload_time))}
                            </tbody>
                        </table>

                    </DialogContent>
                    <DialogActions>
                        {vehicleDetails.length > 0 && <>
                            <Button color='secondary' onClick={(event) => {
                                event.preventDefault()
                                handleSubmit('reject')
                            }}>Reject</Button> <Button onClick={(event) => {
                                event.preventDefault()
                                handleSubmit('accept')
                            }}>Accept</Button>
                        </>}
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}